// src/components/landing/EarlyAdopterSection.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

export function EarlyAdopterSection() {
  const navigate = useNavigate();
  const benefits = [
    'Initial basic features for an entire year.',
    'New tools and features as they launch.',
    'The opportunity to shape the future of the app with your feedback.'
  ];

  return (
    <div className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto"
        >
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Be an Early Adopter
            </h2>
            <button
              onClick={() => navigate('/purchase')}
              className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-3 px-6 rounded-full inline-block mb-4 hover:from-blue-700 hover:to-indigo-700 transition-all duration-200"
            >
              <p className="text-xl font-semibold">
                Special Launch Offer: $50 for a Full Year
              </p>
            </button>
          </div>

          <div className="bg-gradient-to-br from-indigo-50 to-white p-8 rounded-xl shadow-lg mb-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-6">
              Early adopters get exclusive access to:
            </h3>
            <ul className="space-y-4">
              {benefits.map((benefit, index) => (
                <li key={index} className="flex items-center space-x-3">
                  <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                  <span className="text-gray-600">{benefit}</span>
                </li>
              ))}
            </ul>
          </div>

          <p className="text-center text-lg text-indigo-600 italic">
            Don't miss your chance to be part of a movement redefining connection and value.
          </p>
        </motion.div>
      </div>
    </div>
  );
}
