import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, orderBy, getDocs, addDoc, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import { FileText, ChevronDown, ChevronUp } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import type { Experience, Theme, CompletedTheme } from '../lib/firebase/types';
import ThemeOverview from './YourStory/ThemeOverview';
import QuestionModal from './YourStory/QuestionModal';
import ThemeSummaryViewer from './shared/ThemeSummaryViewer';
import LockedThemeModal from './shared/LockedThemeModal';
import ZenLoader from './YearInReview/ZenLoader';
import { generateStructuredInfo } from '../lib/openai';

export default function DailyReflection() {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [experience, setExperience] = useState<Experience | null>(null);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);
  const [showThemeOverview, setShowThemeOverview] = useState(false);
  const [themeContent, setThemeContent] = useState<{ [key: string]: any }>({});
  const [currentDay, setCurrentDay] = useState(1);
  const [showThemeGrid, setShowThemeGrid] = useState(true);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [showNewSummaryViewer, setShowNewSummaryViewer] = useState(false);
  const [expandedThemeId, setExpandedThemeId] = useState<string | null>(null);
  const [createdFor, setCreatedFor] = useState<string>('');
  const [currentContent, setCurrentContent] = useState('');
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [recentCompletions, setRecentCompletions] = useState<CompletedTheme[]>([]);

  useEffect(() => {
    const loadExperience = async () => {
      try {
        setLoading(true);
        console.log('Loading daily reflection experience...');

        // Get the daily reflection experience
        const experiencesRef = collection(db, 'experiences');
        const experienceQuery = query(
          experiencesRef,
          where('route', '==', 'daily-reflection'),
          where('isActive', '==', true)
        );
        const experienceSnapshot = await getDocs(experienceQuery);
        
        if (!experienceSnapshot.empty) {
          const experienceDoc = experienceSnapshot.docs[0];
          const dailyExperience = {
            id: experienceDoc.id,
            ...experienceDoc.data()
          } as Experience;
          
          setExperience(dailyExperience);
          
          // Load themes
          const themesRef = collection(db, 'themes');
          const themesQuery = query(
            themesRef,
            where('experienceId', '==', dailyExperience.id),
            orderBy('order', 'asc')
          );
          const themesSnapshot = await getDocs(themesQuery);
          const themesData = themesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as Theme[];
          
          setThemes(themesData);

          // Load recent completions if user is logged in
          if (user) {
            const completionsRef = collection(db, 'completedThemes');
            const completionsQuery = query(
              completionsRef,
              where('userId', '==', user.uid),
              where('experienceId', '==', dailyExperience.id),
              orderBy('completedAt', 'desc'),
              limit(10)
            );
            const completionsSnapshot = await getDocs(completionsQuery);
            const completions = completionsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            })) as CompletedTheme[];
            setRecentCompletions(completions);
          }
        } else {
          console.error('Daily reflection experience not found');
          toast.error('This experience is not available yet');
          navigate('/');
        }
      } catch (error) {
        console.error('Error loading daily reflection:', error);
        toast.error('Unable to load experience');
      } finally {
        setLoading(false);
      }
    };

    loadExperience();
  }, [navigate, user]);

  const handleThemeSelect = (theme: Theme) => {
    setSelectedTheme(theme);
    setShowThemeOverview(true);
    setShowThemeGrid(false);
  };

  const handleStartTheme = () => {
    setShowThemeOverview(false);
    setCurrentDay(1);
    setCurrentContent('');
    setShowThemeGrid(false);
  };

  const handleSaveResponse = async () => {
    if (!currentContent.trim() || !selectedTheme) return;
    setIsGeneratingSummary(true);

    try {
      const themeId = selectedTheme.id;
      const updatedTheme = {
        ...themeContent,
        [themeId]: {
          ...themeContent[themeId],
          responses: {
            ...(themeContent[themeId]?.responses || {}),
            [currentDay]: currentContent
          }
        }
      };
      setThemeContent(updatedTheme);

      if (currentDay === selectedTheme.questions.length) {
        const responses = updatedTheme[themeId].responses;
        await handleGenerateSummary(responses);
        setSelectedThemeId(themeId);
        setShowNewSummaryViewer(true);
        setShowThemeGrid(true);
        setSelectedTheme(null);
      } else {
        setCurrentDay(prev => prev + 1);
        setCurrentContent('');
      }
    } catch (error) {
      console.error('Error saving response:', error);
      toast.error('Failed to save response');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const handleGenerateSummary = async (responses: { [key: number]: string }) => {
    if (!selectedTheme || !user || !userData) return;
    
    try {
      const content = Object.entries(responses)
        .sort(([a], [b]) => parseInt(a) - parseInt(b))
        .map(([day, content]) => {
          const question = selectedTheme.questions.find(q => q.id === parseInt(day));
          return `Question ${day}: ${question?.text}\nAnswer: ${content}`;
        })
        .join('\n\n');

      const systemPrompt = selectedTheme.systemPrompt || `Create an inspiring summary that highlights key themes, growth, and insights from the user's reflections. Structure the response in markdown format with these sections:

# Theme Journey
A concise narrative overview of the journey, challenges faced, and growth achieved.

## Highlights and Wins
Key accomplishments and positive moments (2-5 bullets)

## Strengths and Skills Used
Core competencies and abilities demonstrated throughout the journey (2-5 bullets)

## Impact of Actions
The effects of decisions and actions on both personal and professional life (2-5 bullets)

## Potential Future Story
A forward-looking paragraph that builds on the insights and growth shown.`;

      const response = await generateStructuredInfo(
        content,
        { model: 'gpt-4', temperature: 0.7 },
        systemPrompt + (createdFor ? `\n\nIMPORTANT: This summary is specifically created for ${createdFor}. Please personalize the insights and recommendations accordingly.` : '')
      );

      const timestamp = new Date().toISOString();
      const completedTheme = {
        userId: user.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        themeId: selectedTheme.id,
        questions: selectedTheme.questions,
        themeType: 'theme',
        experienceId: experience?.id,
        experienceName: experience?.title,
        responses: Object.entries(responses).map(([questionId, content]) => ({
          questionId: parseInt(questionId),
          question: selectedTheme.questions.find(q => q.id === parseInt(questionId))?.text || '',
          content,
          createdAt: timestamp
        })),
        summary: response.data,
        completedAt: timestamp
      };

      await addDoc(collection(db, 'completedThemes'), completedTheme);
      toast.success('Theme completed and saved!');
    } catch (error) {
      console.error('Error generating summary:', error);
      toast.error('Failed to generate summary');
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-purple-200 border-t-purple-600"></div>
          <p className="mt-4 text-purple-600">Loading experience...</p>
        </div>
      </div>
    );
  }

  if (!experience) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-purple-900 mb-4">Experience Not Found</h1>
            <p className="text-purple-600 mb-8">
              This experience is not available yet. Please check back later.
            </p>
            <button
              onClick={() => navigate('/')}
              className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Return Home
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {showThemeGrid ? (
          <>
            <div className="mb-8">
              <h1 className="text-3xl font-bold text-purple-900 mb-2">{experience.title}</h1>
              <p className="text-gray-600">{experience.description}</p>
              
              {recentCompletions.length > 0 && (
                <div className="mt-8">
                  <button
                    onClick={() => setExpandedThemeId(expandedThemeId ? null : 'recent')}
                    className="w-full flex items-center justify-between p-4 bg-blue-50/50 rounded-t-xl shadow-sm hover:bg-blue-50/70 transition-colors"
                  >
                    <div className="flex items-center space-x-2">
                      <h2 className="text-xl font-semibold text-purple-900">Recent Reflections</h2>
                      <span className="text-sm text-gray-500">({recentCompletions.length})</span>
                    </div>
                    {expandedThemeId === 'recent' ? (
                      <ChevronUp className="w-5 h-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-400" />
                    )}
                  </button>
                  
                  {expandedThemeId === 'recent' && (
                    <div className="bg-white rounded-b-xl shadow-sm p-6">
                      <div className="space-y-4">
                        {recentCompletions.map((completion) => (
                          <div 
                            key={completion.id}
                            className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                          >
                            <div>
                              <h3 className="font-medium text-purple-900">
                                {completion.themeTitle} Summary
                                {completion.createdFor && (
                                  <span className="ml-2 text-sm text-gray-600">
                                    (Created for {completion.createdFor})
                                  </span>
                                )}
                              </h3>
                              <p className="text-sm text-gray-500">
                                Completed {new Date(completion.completedAt).toLocaleDateString()}
                              </p>
                            </div>
                            <div className="flex items-center space-x-2">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedThemeId(completion.themeId);
                                  setShowNewSummaryViewer(true);
                                }}
                                className="p-2 text-purple-600 hover:text-purple-700 hover:bg-purple-50 rounded-lg transition-colors"
                              >
                                <FileText className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {themes.map((theme) => (
                <div
                  key={theme.id}
                  className="bg-white p-6 rounded-xl shadow-sm transition-all"
                >
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="text-xl font-semibold text-purple-900">{theme.title}</h3>
                      <p className="text-gray-900 mt-1">{theme.description}</p>
                      <button
                        onClick={() => setExpandedThemeId(expandedThemeId === theme.id ? null : theme.id)}
                        className="mt-2 text-sm text-purple-600 hover:text-purple-700"
                      >
                        {expandedThemeId === theme.id ? 'Hide Questions' : 'Preview Questions'}
                      </button>
                    </div>
                  </div>

                  {expandedThemeId === theme.id && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      className="overflow-hidden mb-4"
                    >
                      <div className="space-y-2">
                        {theme.questions.map((question, index) => (
                          <div
                            key={question.id}
                            className="flex items-center space-x-2 text-sm text-gray-600 p-2 rounded-lg hover:bg-purple-50"
                          >
                            <span className="w-5 h-5 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-medium">
                              {index + 1}
                            </span>
                            <span className="flex-1">{question.text}</span>
                          </div>
                        ))}
                      </div>
                    </motion.div>
                  )}

                  <div className="mt-4">
                    <button
                      onClick={() => handleThemeSelect(theme)}
                      className="w-full px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                    >
                      Start Reflection
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <QuestionModal
            theme={selectedTheme}
            currentQuestion={currentDay}
            answers={themeContent[selectedTheme?.id]?.responses || {}}
            onClose={() => setShowThemeGrid(true)}
            onAnswer={(questionId, answer) => {
              if (!selectedTheme) return;
              setCurrentContent(answer);
              setThemeContent(prev => ({
                ...prev,
                [selectedTheme.id]: {
                  ...prev[selectedTheme.id],
                  responses: {
                    ...(prev[selectedTheme.id]?.responses || {}),
                    [questionId]: answer
                  }
                }
              }));
            }}
            onNext={() => setCurrentDay(prev => prev + 1)}
            onPrevious={() => setCurrentDay(prev => prev - 1)}
            onComplete={handleSaveResponse}
            isGeneratingSummary={isGeneratingSummary}
          />
        )}
      </div>
      
      {selectedTheme && (
        <ThemeOverview
          isOpen={showThemeOverview}
          onClose={() => setShowThemeOverview(false)}
          title={selectedTheme.title}
          description={selectedTheme.description}
          introduction={experience.customPrompt || ''}
          showCreatedFor={selectedTheme.showCreatedFor}
          onCreatedForChange={setCreatedFor}
          createdFor={createdFor}
          onStart={handleStartTheme}
        />
      )}
      
      <LockedThemeModal
        isOpen={showLockedModal}
        onClose={() => setShowLockedModal(false)}
      />
      
      {isGeneratingSummary && <ZenLoader />}
      
      {selectedThemeId && user && (
        <ThemeSummaryViewer
          isOpen={showNewSummaryViewer}
          onClose={() => {
            setShowNewSummaryViewer(false);
            setSelectedThemeId(null);
          }}
          themeId={selectedThemeId}
          userId={user.uid}
        />
      )}
    </div>
  );
}