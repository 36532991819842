import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageCircle, ChevronDown, ChevronUp, Download, Share2 } from 'lucide-react';
import jsPDF from 'jspdf';
import { collection, query, where, orderBy, getDocs, addDoc, limit } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { getThemeById } from '../../lib/firebase/themes';
import type { CompletedTheme } from '../../lib/firebase/types';

interface ThemeSummaryViewerProps {
  isOpen: boolean;
  onClose: () => void;
  themeId: string;
  userId: string;
}

export default function ThemeSummaryViewer({
  isOpen,
  onClose,
  themeId,
  userId
}: ThemeSummaryViewerProps) {
  const [loading, setLoading] = React.useState(true);
  const [showResponses, setShowResponses] = React.useState(false);
  const [completedTheme, setCompletedTheme] = React.useState<CompletedTheme | null>(null);
  const [showShareMenu, setShowShareMenu] = React.useState(false);
  const [publicShareId, setPublicShareId] = React.useState<string | null>(null);

  const createPublicShare = async () => {
    if (!completedTheme) return null;
    try {
      const shareData = {
        themeId: completedTheme.themeId,
        summary: completedTheme.summary,
        createdAt: new Date().toISOString(),
        expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString() // 7 days
      };
      const docRef = await addDoc(collection(db, 'publicShares'), shareData);
      return docRef.id;
    } catch (error) {
      console.error('Error creating public share:', error);
      return null;
    }
  };

  const handleShare = async (platform: 'copy' | 'twitter' | 'linkedin' | 'email') => {
    if (!completedTheme) return;

    const title = completedTheme.themeTitle;
    let shareId = publicShareId;

    if (!shareId) {
      shareId = await createPublicShare();
      setPublicShareId(shareId);
    }

    const url = shareId ? `${window.location.origin}/share/${shareId}` : window.location.href;
    const text = `Check out my ${title} reflection on Finding Blueberries!`;

    switch (platform) {
      case 'copy':
        await navigator.clipboard.writeText(url);
        toast.success('Link copied to clipboard!');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`);
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`);
        break;
      case 'email': {
        // Generate PDF and create email with attachment
        const pdfBlob = await generatePDF();
        const emailSubject = encodeURIComponent(`${title} Summary from Finding Blueberries`);
        const emailBody = encodeURIComponent(`${text}\n\nView online: ${url}`);
        const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
        
        // Create a temporary download link for the PDF
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = `${title.toLowerCase().replace(/\s+/g, '-')}-summary.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        
        // Open email client
        window.location.href = mailtoLink;
        break;
      }
    }
    setShowShareMenu(false);
  };

  const generatePDF = async () => {
    if (!completedTheme) return;

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 20;
    let yPos = margin;
    const timestamp = new Date().toLocaleString();

    // Title
    doc.setFontSize(24);
    doc.text(`${completedTheme.themeTitle} Summary`, pageWidth / 2, yPos, { align: 'center' });
    yPos += 20;

    // Add "Created for" if present
    if (completedTheme.createdFor) {
      doc.setFontSize(14);
      doc.setTextColor(75, 85, 99);
      doc.text(`Created for: ${completedTheme.createdFor}`, pageWidth / 2, yPos, { align: 'center' });
      yPos += 15;
    }

    // User and Date
    doc.setFontSize(12);
    doc.setTextColor(128, 128, 128);
    doc.text(`Generated for ${completedTheme.userName}`, margin, yPos);
    doc.text(timestamp, pageWidth - margin, yPos, { align: 'right' });
    yPos += 10;

    // Content
    doc.setFontSize(12);
    doc.setTextColor(75, 85, 99);
    const lines = doc.splitTextToSize(completedTheme.summary, pageWidth - (margin * 2));
    doc.text(lines, margin, yPos);

    // Footer
    doc.setFontSize(10);
    doc.setTextColor(128, 128, 128);
    const footer = 'Visit findingblueberries.com to try for yourself';
    const footerLink = 'https://findingblueberries.com';
    const footerY = pageHeight - margin;
    doc.text(footer, pageWidth / 2, footerY, { align: 'center' });
    doc.setTextColor(124, 58, 237);
    doc.textWithLink('findingblueberries.com', pageWidth / 2 - 20, footerY + 5, { url: footerLink });

    return doc.output('blob');
  };

  const handleDownloadPDF = async () => {
    const pdfBlob = await generatePDF();
    const fileName = `${completedTheme?.themeTitle.toLowerCase().replace(/\s+/g, '-')}-summary-${new Date().toISOString().replace(/[:.]/g, '-')}.pdf`;
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const loadTheme = React.useCallback(async () => {
    if (!themeId || !userId) return;
    
    console.log('Loading theme summary:', { themeId, userId });
    try {
      setLoading(true);
      // Query for the most recent completion of this theme by this user
      const themesRef = collection(db, 'completedThemes');
      const q = query(
        themesRef,
        where('userId', '==', userId),
        where('themeId', '==', themeId),
        orderBy('completedAt', 'desc'),
        limit(1)
      );
      
      console.log('Fetching theme summary:', { userId, themeId });
      const snapshot = await getDocs(q);
      console.log('Query results:', { empty: snapshot.empty, size: snapshot.size });
      
      if (!snapshot.empty) {
        const themeData = snapshot.docs[0].data() as CompletedTheme;
        
        // Get theme title from themes collection if not in completed theme
        if (themeData.themeType === 'experience_summary') {
          themeData.themeTitle = 'Experience Summary';
          themeData.summaryTitle = 'Experience Summary';
        } else if (!themeData.themeTitle) {
          const theme = await getThemeById(themeId);
          if (theme) {
            themeData.themeTitle = theme.title;
            themeData.summaryTitle = `${theme.title} Summary`;
          }
        }
        
        console.log('Found theme data:', themeData);
        console.log('Found theme summary:', themeData);
        setCompletedTheme(themeData);
      } else {
        console.log('No theme summary found');
        console.log('No theme summary found');
        toast.error('Unable to load theme summary');
        onClose();
      }
    } catch (error) {
      console.error('Error loading theme summary:', error, error instanceof Error ? error.message : '');
      toast.error('Failed to load theme summary');
      onClose();
    } finally {
      setLoading(false);
    }
  }, [themeId, userId, onClose]);

  React.useEffect(() => {
    if (isOpen && themeId && userId) {
      loadTheme();
    }
  }, [isOpen, loadTheme]);

  if (!isOpen || !completedTheme) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            key="overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            key="content"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 z-50"
          >
        <div className="bg-white rounded-xl max-w-4xl w-full p-8 relative max-h-[90vh] overflow-y-auto">
          {loading ? (
            <div className="flex items-center justify-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
            </div>
          ) : (
            <>
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>

              <div className="mb-6">
                <h2 className="text-2xl font-bold text-purple-900 mb-4">
                  {completedTheme.summaryTitle || `${completedTheme.themeTitle} Summary`}
                </h2>
                <div className="flex items-center justify-end space-x-4 mt-2">
                      <button
                        onClick={handleDownloadPDF}
                        className="flex items-center space-x-2 px-4 py-2 text-indigo-600 hover:text-indigo-700 transition-colors text-sm"
                      >
                        <Download className="w-4 h-4" />
                        <span>Download PDF</span>
                      </button>
                      <div className="relative">
                        <button
                          onClick={() => setShowShareMenu(!showShareMenu)}
                          className="flex items-center space-x-2 px-4 py-2 text-indigo-600 hover:text-indigo-700 transition-colors text-sm"
                        >
                          <Share2 className="w-4 h-4" />
                          <span>Share</span>
                        </button>
                      {showShareMenu && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                          <button
                            onClick={() => handleShare('copy')}
                            className="w-full px-4 py-2 text-left text-purple-600 hover:bg-purple-50 text-sm"
                          >
                            Copy Link
                          </button>
                          <button
                            onClick={() => handleShare('twitter')}
                            className="w-full px-4 py-2 text-left text-purple-600 hover:bg-purple-50 text-sm"
                          >
                            Share on Twitter
                          </button>
                          <button
                            onClick={() => handleShare('linkedin')}
                            className="w-full px-4 py-2 text-left text-purple-600 hover:bg-purple-50 text-sm"
                          >
                            Share on LinkedIn
                          </button>
                          <button
                            onClick={() => handleShare('email')}
                            className="w-full px-4 py-2 text-left text-purple-600 hover:bg-purple-50 text-sm"
                          >
                            Share via Email
                          </button>
                        </div>
                      )}
                      </div>
                </div>
                
                <button
                  onClick={() => setShowResponses(!showResponses)}
                  className="w-full flex items-center justify-between p-4 bg-purple-50 rounded-lg hover:bg-purple-100 transition-colors"
                >
                  <div className="flex items-center space-x-2">
                    <MessageCircle className="w-5 h-5 text-purple-600" />
                    <span className="font-medium text-purple-900">Your Responses</span>
                  </div>
                  {showResponses ? (
                    <ChevronUp className="w-5 h-5 text-purple-600" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-purple-600" />
                  )}
                </button>
                
                {showResponses && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    className="mt-4 space-y-4 bg-white p-6 rounded-lg border border-purple-100"
                  >
                    {completedTheme.responses.map((response) => (
                      <div key={response.questionId} className="space-y-2">
                        <p className="font-medium text-purple-900">
                          {completedTheme.questions?.find(q => q.id === response.questionId)?.text || response.question}
                        </p>
                        <p className="text-gray-600 whitespace-pre-wrap">{response.content}</p>
                      </div>
                    ))}
                  </motion.div>
                )}
              </div>

              <div className="prose max-w-none [&>ul]:list-none [&>ul]:pl-0">
                {completedTheme.createdFor && (
                  <p className="text-lg text-purple-600 mb-6">Created for: {completedTheme.createdFor}</p>
                )}
                <ReactMarkdown>{completedTheme.summary}</ReactMarkdown>
              </div>
            </>
          )}
        </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}