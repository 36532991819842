import React from 'react';
import { Link } from 'react-router-dom';
import { BookOpen, Map, Sparkles } from 'lucide-react';

export default function AdminApps() {
  const apps = [
    {
      path: '/year-in-review', 
      name: 'Year In Review', 
      icon: BookOpen,
      description: 'Reflect on your journey and achievements'
    },
    {
      path: '/success-map', 
      name: 'Success Map', 
      icon: Map,
      description: 'Navigate your path to success'
    },
    {
      path: '/concept-experience', 
      name: 'Concept Experience', 
      icon: BookOpen,
      description: 'Original Year In Review experience'
    },
    {
      path: '/analyzer',
      name: 'AI Analyzer',
      icon: Sparkles,
      description: 'Get AI-powered insights from your reflections'
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-6">
        Applications
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {apps.map((app) => {
          const Icon = app.icon;
          return (
            <Link
              key={app.path}
              to={app.path}
              className="flex flex-col items-center p-6 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <Icon className="w-8 h-8 text-indigo-600 mb-3" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {app.name}
              </h3>
              <p className="text-sm text-gray-600 text-center">
                {app.description}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
}