import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import { auth, db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';

const handlePaymentSubmit = async (event: React.FormEvent) => {
  event.preventDefault();

  try {
    const response = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Success:', data);
    window.location.href = data.url;

  } catch (error) {
    console.error('Error:', error);
  }
};

export default function PurchaseAuth() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const timestamp = new Date().toISOString();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Check group code if provided
      let groupData = null;
      if (groupCode.trim()) {
        const groupsRef = collection(db, 'groups');
        const q = query(groupsRef, where('group_code', '==', groupCode.trim()));
        const snapshot = await getDocs(q);
        
        if (!snapshot.empty) {
          groupData = snapshot.docs[0].data();
          
          // Validate group
          if (groupData.seats_remaining <= 0) {
            throw new Error('This group is currently full. Please contact support.');
          }
          
          const currentDate = new Date();
          const startDate = new Date(groupData.start_date);
          const endDate = new Date(groupData.end_date);
          
          if (currentDate < startDate || currentDate > endDate) {
            throw new Error('This group code is not currently active.');
          }
        } else {
          throw new Error('Invalid group code. Please check and try again.');
        }
      }
      
      // Create user document
      await setDoc(doc(db, 'users', userId), {
        firstName,
        lastName,
        email: email.toLowerCase(),
        group: groupCode.trim() || null,
        access_level: 'free', // Will be upgraded after payment
        createdAt: timestamp,
        membership_start: groupData ? timestamp : null,
        membership_end: groupData ? groupData.end_date : null
      });

      // Update group if joining one
      if (groupData) {
        const groupRef = doc(db, 'groups', snapshot.docs[0].id);
        await updateDoc(groupRef, {
          members: arrayUnion(userId),
          seats_used: (groupData.seats_used || 0) + 1,
          seats_remaining: Math.max(0, (groupData.seats_remaining || 0) - 1)
        });
      }

      toast.success('Account created successfully!');
      // Payment flow will be handled here later
    } catch (err: any) {
      console.error('Error signing up:', err);
      const errorMessage = 
        err.code === 'auth/email-already-in-use' ? 'An account already exists with this email' :
        err.message || 'Failed to create account. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
            First Name
          </label>
          <input
            id="firstName"
            type="text"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
            Last Name
          </label>
          <input
            id="lastName"
            type="text"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <input
          id="email"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          Password
        </label>
        <input
          id="password"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
      
      <div>
        <label htmlFor="groupCode" className="block text-sm font-medium text-gray-700">
          Group Code (Optional)
        </label>
        <input
          id="groupCode"
          type="text"
          value={groupCode}
          onChange={(e) => setGroupCode(e.target.value)}
          placeholder="Enter your group code if you have one"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
        <p className="mt-1 text-sm text-gray-500">
          If you have a group code from your organization, enter it here
        </p>
      </div>

      {error && (
        <div className="text-red-600 text-sm text-center">{error}</div>
      )}

      <button
        type="submit"
        disabled={loading}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        {loading ? 'Creating Account...' : 'Continue to Payment'}
      </button>
    </form>
  );
}