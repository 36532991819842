import React, { createContext, useContext, useState, useEffect } from 'react';

interface Reflection {
  id: string;
  content: string;
  day: number;
  week: number;
  date: string;
}

interface ReflectionsContextType {
  reflections: Reflection[];
  addReflection: (reflection: Omit<Reflection, 'id'>) => void;
  removeReflection: (id: string) => void;
}

const ReflectionsContext = createContext<ReflectionsContextType | undefined>(undefined);

export function ReflectionsProvider({ children }: { children: React.ReactNode }) {
  // Initialize as empty array
  const [reflections, setReflections] = useState<Reflection[]>([]);

  useEffect(() => {
    try {
      const savedReflections = localStorage.getItem('reflections');
      if (savedReflections) {
        // Parse and validate the saved reflections
        const parsed = JSON.parse(savedReflections);
        if (Array.isArray(parsed)) {
          setReflections(parsed);
        } else {
          // If not an array, initialize as empty array
          setReflections([]);
          localStorage.setItem('reflections', JSON.stringify([]));
        }
      }
    } catch (error) {
      console.error('Error loading reflections:', error);
      // Reset to empty array if there's an error
      setReflections([]);
      localStorage.setItem('reflections', JSON.stringify([]));
    }
  }, []);

  const addReflection = (reflection: Omit<Reflection, 'id'>) => {
    try {
      const newReflection = {
        ...reflection,
        id: crypto.randomUUID()
      };
      // Ensure we're spreading an array
      const updatedReflections = Array.isArray(reflections) 
        ? [...reflections, newReflection]
        : [newReflection];
        
      setReflections(updatedReflections);
      localStorage.setItem('reflections', JSON.stringify(updatedReflections));
    } catch (error) {
      console.error('Error adding reflection:', error);
    }
  };

  const removeReflection = (id: string) => {
    try {
      const updatedReflections = reflections.filter(r => r.id !== id);
      setReflections(updatedReflections);
      localStorage.setItem('reflections', JSON.stringify(updatedReflections));
    } catch (error) {
      console.error('Error removing reflection:', error);
    }
  };

  const value = {
    reflections,
    addReflection,
    removeReflection
  };

  return (
    <ReflectionsContext.Provider value={value}>
      {children}
    </ReflectionsContext.Provider>
  );
}

export function useReflections() {
  const context = useContext(ReflectionsContext);
  if (context === undefined) {
    throw new Error('useReflections must be used within a ReflectionsProvider');
  }
  return context;
}