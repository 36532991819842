import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { toast } from 'react-hot-toast';

export function RequirePaidAccess({ children }: { children: React.ReactNode }) {
  const { userData } = useAuth();
  const [hasShownMessage, setHasShownMessage] = useState(false);

  if (userData?.access_level === 'free') {
    if (!hasShownMessage) {
      toast.error('This feature requires a paid membership. Please contact support to upgrade.');
      setHasShownMessage(true);
    }
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}