import React from 'react';
import { motion } from 'framer-motion';

export default function ZenLoader() {
  return (
    <div className="fixed inset-0 bg-white/90 backdrop-blur-sm flex items-center justify-center z-[100]">
      <div className="space-y-8">
        <div className="relative mx-auto w-24 h-24">
          <motion.div
            className="absolute inset-0 border-4 border-purple-200 rounded-full"
            animate={{ rotate: 360 }}
            transition={{
              duration: 8,
              ease: "linear",
              repeat: Infinity
            }}
          />
          <motion.div
            className="absolute inset-2 border-4 border-purple-300 rounded-full"
            animate={{ rotate: -360 }}
            transition={{
              duration: 6,
              ease: "linear",
              repeat: Infinity
            }}
          />
          <motion.div
            className="absolute inset-4 border-4 border-purple-400 rounded-full"
            animate={{ rotate: 360 }}
            transition={{
              duration: 4,
              ease: "linear",
              repeat: Infinity
            }}
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-2xl">🫐</span>
          </div>
        </div>
        <div className="text-center space-y-2">
          <h3 className="text-xl font-semibold text-purple-900">Finding Clarity</h3>
          <p className="text-purple-600">Analyzing your reflections and crafting insights...</p>
        </div>
      </div>
    </div>
  );
}