// src/components/landing/TeamsSection.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Users, BookOpen, Building2, Heart } from 'lucide-react';

export function TeamsSection() {
  const useCases = [
    {
      icon: Users,
      text: 'Team development and performance reviews.'
    },
    {
      icon: BookOpen,
      text: 'Creating meaningful group activities.'
    },
    {
      icon: Heart,
      text: 'Building stronger, more connected communities.'
    }
  ];

  return (
    <div className="bg-gradient-to-b from-white to-indigo-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto text-center"
        >
          <div className="inline-block p-3 bg-indigo-100 rounded-2xl mb-8">
            <Building2 className="w-8 h-8 text-indigo-600" />
          </div>

          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            For Teams and Organizations
          </h2>
          
          <p className="text-xl text-gray-600 mb-12">
            Bring Clarity to Your Group.
          </p>

          <div className="grid md:grid-cols-3 gap-8 mb-12">
            {useCases.map((useCase, index) => {
              const Icon = useCase.icon;
              return (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  className="bg-white p-6 rounded-xl shadow-sm"
                >
                  <div className="flex flex-col items-center space-y-4">
                    <div className="p-2 bg-indigo-100 rounded-lg">
                      <Icon className="w-6 h-6 text-indigo-600" />
                    </div>
                    <p className="text-gray-600">{useCase.text}</p>
                  </div>
                </motion.div>
              );
            })}
          </div>

          <p className="text-lg text-gray-600">
            Want a custom solution?{' '}
            <a 
              href="mailto:brian@findinggood.com" 
              className="text-indigo-600 hover:text-indigo-700 font-medium"
            >
              Contact us
            </a>
            {' '}to learn how we can partner with your organization.
          </p>
        </motion.div>
      </div>
    </div>
  );
}
