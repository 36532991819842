import React from 'react';
import { motion } from 'framer-motion';
import { Heart } from 'lucide-react';

export default function SuccessBanner() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white py-4"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center space-x-2 text-center">
          <Heart className="w-5 h-5" />
          <p className="text-lg font-medium">
            Purchase successful, authentic connection awaits! Our small but extremely dedicated team thanks you!
          </p>
        </div>
      </div>
    </motion.div>
  );
}