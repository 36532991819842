import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight, Brain, Users, Sparkles } from 'lucide-react';

interface BenefitsSectionProps {
  onSignUp: () => void;
}

export function BenefitsSection({ onSignUp }: BenefitsSectionProps) {
  const navigate = useNavigate();

  return (
    <div className="bg-gradient-to-b from-white to-indigo-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 text-center mb-12">
            What You'll Gain
          </h2>

          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <div className="flex items-center space-x-4 mb-4">
                <div className="p-3 bg-indigo-100 rounded-lg">
                  <Brain className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">
                  Clarifying Your Own Value
                </h3>
              </div>
              <p className="text-gray-600">
                Get a clearer picture of your strengths, purpose, and impact by reflecting on past experiences.
              </p>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-lg">
              <div className="flex items-center space-x-4 mb-4">
                <div className="p-3 bg-purple-100 rounded-lg">
                  <Users className="w-6 h-6 text-purple-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">
                  Making Connection Easier
                </h3>
              </div>
              <p className="text-gray-600">
                Spark meaningful conversations that lead to deeper connections by reflecting on experiences with others.
              </p>
            </div>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-lg mb-12">
            <div className="flex items-center space-x-4 mb-6">
              <div className="p-3 bg-blue-100 rounded-lg">
                <Sparkles className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900">
                Transform Your Reflections into "Value Summaries"
              </h3>
            </div>

            <ul className="space-y-4 mb-8">
              <li className="flex items-start space-x-3">
                <span className="text-indigo-600 font-bold">•</span>
                <span className="text-gray-600">Highlight what's working for you and others.</span>
              </li>
              <li className="flex items-start space-x-3">
                <span className="text-indigo-600 font-bold">•</span>
                <span className="text-gray-600">Provide actionable insights and uncover hidden opportunities.</span>
              </li>
              <li className="flex items-start space-x-3">
                <span className="text-indigo-600 font-bold">•</span>
                <span className="text-gray-600">Identify your strengths, wins, and impact.</span>
              </li>
              <li className="flex items-start space-x-3">
                <span className="text-indigo-600 font-bold">•</span>
                <span className="text-gray-600">Offer a clear narrative to help you grow, connect, and thrive.</span>
              </li>
            </ul>

            <p className="text-gray-600 italic">
              And with each Value Summary you create for yourself or someone else, you'll not only feel more connected—you'll grow more confident in your ability to connect with others.
            </p>
          </div>

          <div className="text-center">
            <motion.button
              onClick={() => navigate('/purchase')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group inline-flex items-center space-x-3 px-8 py-4 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-blue-700 hover:to-indigo-700 transition-all duration-200"
            >
              <span>Join Now</span>
              <ArrowRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
            </motion.button>
          </div>
        </motion.div>
      </div>
    </div>
  );
}