import React, { useState } from 'react';
import { Search, Settings, ChevronDown, ChevronUp } from 'lucide-react';
import type { AIConfig } from '../App';

interface SplitAnalyzeButtonProps {
  onAnalyze: (e: React.FormEvent) => void;
  loading: boolean;
  disabled: boolean;
  config: AIConfig;
  onConfigChange: (config: AIConfig) => void;
  label: string;
}

const AVAILABLE_MODELS = [
  { id: 'gpt-4-turbo-preview', name: 'GPT-4 Turbo' },
  { id: 'gpt-4', name: 'GPT-4' },
  { id: 'gpt-3.5-turbo', name: 'GPT-3.5 Turbo' }
];

export function SplitAnalyzeButton({
  onAnalyze,
  loading,
  disabled,
  config,
  onConfigChange,
  label
}: SplitAnalyzeButtonProps) {
  const [showSettings, setShowSettings] = useState(false);

  return (
    <div className="relative">
      <div className="flex">
        <button
          onClick={onAnalyze}
          disabled={loading || disabled}
          className="flex-1 flex items-center justify-center px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 text-white text-lg font-semibold rounded-l-lg disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 shadow-md hover:shadow-lg"
        >
          <Search className="w-6 h-6 mr-3" />
          {loading ? 'Performing Magic...' : label}
        </button>
        <button
          type="button"
          onClick={() => setShowSettings(!showSettings)}
          className="flex items-center px-4 bg-gradient-to-r from-purple-600 to-purple-700 hover:from-purple-700 hover:to-purple-800 text-white border-l border-purple-500/30 rounded-r-lg hover:shadow-lg transition-all duration-200"
        >
          <Settings className="w-5 h-5" />
          {showSettings ? (
            <ChevronUp className="w-5 h-5 ml-2" />
          ) : (
            <ChevronDown className="w-5 h-5 ml-2" />
          )}
        </button>
      </div>

      {showSettings && (
        <div className="absolute right-0 bottom-full mb-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-10">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Model
              </label>
              <select
                value={config.model}
                onChange={(e) => onConfigChange({ ...config, model: e.target.value })}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              >
                {AVAILABLE_MODELS.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Temperature: {config.temperature.toFixed(1)}
              </label>
              <input
                type="range"
                min="0"
                max="2"
                step="0.1"
                value={config.temperature}
                onChange={(e) => onConfigChange({ ...config, temperature: parseFloat(e.target.value) })}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-indigo-600"
              />
              <div className="flex justify-between text-xs text-gray-500 mt-1">
                <span>Consistent</span>
                <span>Creative</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}