import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { Download } from 'lucide-react';
import { jsPDF } from 'jspdf';

export default function PublicShare() {
  const { shareId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [share, setShare] = useState<any>(null);

  useEffect(() => {
    const loadShare = async () => {
      if (!shareId) return;
      
      try {
        const shareDoc = await getDoc(doc(db, 'publicShares', shareId));
        if (!shareDoc.exists()) {
          setError('This shared summary is no longer available');
          return;
        }

        const data = shareDoc.data();
        // Check if share has expired
        if (data.expiresAt && new Date(data.expiresAt) < new Date()) {
          setError('This shared summary has expired');
          return;
        }

        setShare(data);
      } catch (error) {
        console.error('Error loading shared summary:', error);
        setError('Unable to load shared summary');
      } finally {
        setLoading(false);
      }
    };

    loadShare();
  }, [shareId]);

  const handleDownloadPDF = () => {
    if (!share) return;

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 20;
    let yPos = margin;
    const timestamp = new Date().toLocaleString();

    // Title
    doc.setFontSize(24);
    doc.text('Theme Summary', pageWidth / 2, yPos, { align: 'center' });
    yPos += 20;

    // Content
    doc.setFontSize(12);
    doc.setTextColor(75, 85, 99);
    const lines = doc.splitTextToSize(share.summary, pageWidth - (margin * 2));
    doc.text(lines, margin, yPos);

    // Footer
    doc.setFontSize(10);
    doc.setTextColor(128, 128, 128);
    const footer = 'Visit findingblueberries.com to try for yourself';
    const footerLink = 'https://findingblueberries.com';
    const footerY = pageHeight - margin;
    doc.text(footer, pageWidth / 2, footerY, { align: 'center' });
    doc.setTextColor(124, 58, 237);
    doc.textWithLink('findingblueberries.com', pageWidth / 2 - 20, footerY + 5, { url: footerLink });

    const fileName = `theme-summary-${new Date().toISOString().replace(/[:.]/g, '-')}.pdf`;
    doc.save(fileName);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">{error}</h2>
          <p className="text-gray-600">
            Visit <a href="/" className="text-purple-600 hover:text-purple-700">Finding Blueberries</a> to create your own reflections.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-lg p-8"
        >
          <div className="flex justify-between items-start mb-8">
            <h1 className="text-2xl font-bold text-gray-900">Theme Summary</h1>
            <button
              onClick={handleDownloadPDF}
              className="flex items-center space-x-2 px-4 py-2 text-purple-600 hover:text-purple-700 transition-colors"
            >
              <Download className="w-4 h-4" />
              <span>Download PDF</span>
            </button>
          </div>

          <div className="prose prose-purple max-w-none">
            <ReactMarkdown>{share?.summary || ''}</ReactMarkdown>
          </div>

          <div className="mt-8 pt-6 border-t border-gray-100">
            <p className="text-sm text-gray-500 text-center">
              This summary was shared from{' '}
              <a href="/" className="text-purple-600 hover:text-purple-700">
                Finding Blueberries
              </a>
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}