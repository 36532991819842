interface ThemeDetails {
  title: string;
  description: string;
}

const THEME_DETAILS: Record<string, ThemeDetails> = {
  theme1: { 
    title: 'Accomplishments and Pride',
    description: 'Celebrate your achievements and moments of pride from the past year'
  },
  theme2: { 
    title: 'Learning and Growth',
    description: 'Reflect on how you have developed and what you have learned'
  },
  theme3: { 
    title: 'Relationships and Connections',
    description: 'Explore how your relationships evolved and grew stronger'
  },
  theme4: { 
    title: 'Future Vision',
    description: 'Look ahead and plan your path forward'
  }
};

export function getThemeInfo(themeId: string): ThemeDetails {
  if (themeId === 'experience_summary') {
    return {
      title: 'Year In Review',
      description: 'A comprehensive overview of your entire journey'
    };
  }
  
  return THEME_DETAILS[themeId] || { 
    title: `Theme ${themeId}`,
    description: 'Custom theme'
  };
}

export function getThemeTitle(themeId: string): string {
  return getThemeInfo(themeId).title;
}