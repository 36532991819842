import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Home } from 'lucide-react';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-sky-100 to-indigo-50 flex flex-col items-center justify-center p-4">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <div className="text-9xl mb-8">🫐</div>
        <h1 className="text-4xl font-bold text-indigo-900 mb-4">
          Oops! Berry Not Found
        </h1>
        <p className="text-lg text-indigo-700 mb-8">
          Looks like these blueberries aren't where we expected them to be.
        </p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/')}
          className="flex items-center justify-center space-x-2 px-8 py-4 bg-indigo-600 text-white rounded-full text-lg font-medium shadow-lg hover:bg-indigo-700 hover:shadow-xl transition-all duration-200 mx-auto"
        >
          <Home className="w-5 h-5" />
          <span>Return Home</span>
        </motion.button>
      </motion.div>
    </div>
  );
}