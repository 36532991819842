import React from 'react';
import { motion } from 'framer-motion';
import { X, Download } from 'lucide-react';
import { jsPDF } from 'jspdf';
import { CompletedExperience, getCompletedExperiences } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import ReactMarkdown from 'react-markdown';
import { stages } from '../data/stages';

interface DashboardProps {
  onClose: () => void;
}

export default function Dashboard({ onClose }: DashboardProps) {
  const { user } = useAuth();
  const [experiences, setExperiences] = React.useState<CompletedExperience[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedExperience, setSelectedExperience] = React.useState<CompletedExperience | null>(null);

  React.useEffect(() => {
    const loadExperiences = async () => {
      if (!user) return;
      try {
        const completedExperiences = await getCompletedExperiences(user.uid);
        setExperiences(completedExperiences);
      } catch (error) {
        console.error('Error loading completed experiences:', error);
      } finally {
        setLoading(false);
      }
    };

    loadExperiences();
  }, [user]);

  const downloadPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;
    let yPos = margin;

    // Title
    doc.setFontSize(24);
    doc.setTextColor(75, 85, 99);
    doc.text('Your Journey Summary', pageWidth / 2, yPos, { align: 'center' });
    yPos += 20;

    experiences.forEach((experience) => {
      // Experience Title
      doc.setFontSize(18);
      doc.setTextColor(31, 41, 55);
      doc.text(experience.experienceName, margin, yPos);
      yPos += 10;

      experience.themes.forEach((theme) => {
        // Theme Title
        doc.setFontSize(14);
        doc.setTextColor(75, 85, 99);
        doc.text(theme.themeName, margin, yPos);
        yPos += 7;

        // Theme Summary
        doc.setFontSize(12);
        doc.setTextColor(31, 41, 55);
        const lines = doc.splitTextToSize(theme.summary, pageWidth - (margin * 2));
        doc.text(lines, margin, yPos);
        yPos += (lines.length * 7) + 10;

        // Add new page if needed
        if (yPos > doc.internal.pageSize.getHeight() - 20) {
          doc.addPage();
          yPos = margin;
        }
      });

      yPos += 10;
    });

    doc.save('journey-summary.pdf');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">My Journey</h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={downloadPDF}
            className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <Download className="w-4 h-4" />
            <span>Download PDF</span>
          </button>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      </div>

      {experiences.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <p className="text-gray-600">No completed experiences yet. Start your journey!</p>
        </div>
      ) : (
        <div className="space-y-8">
          {experiences.map((experience) => (
            <div key={experience.id} className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="bg-gradient-to-r from-purple-50 to-indigo-50 px-6 py-4 border-b border-gray-100">
                <h3 className="text-xl font-semibold text-gray-900">{experience.experienceName}</h3>
                <p className="text-sm text-gray-600 mt-1">
                  Completed on {new Date(experience.completedAt).toLocaleDateString()}
                </p>
              </div>

              <div className="p-6">
                <div className="space-y-6">
                  {experience.themes.map((theme) => (
                    <div key={theme.themeId} className="border-b border-gray-100 pb-6 last:border-0 last:pb-0">
                      <h4 className="text-lg font-medium text-gray-800 mb-4">{theme.themeName}</h4>
                      <div className="prose prose-sm max-w-none">
                        <ReactMarkdown>{theme.summary}</ReactMarkdown>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}