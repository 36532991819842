import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { ArrowRight, Brain, Users, CalendarDays, Sparkles } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import SuccessBanner from './shared/SuccessBanner';
import { HeroSection } from './landing/HeroSection';
import { PainSection } from './landing/PainSection';
import { BenefitsSection } from './landing/BenefitsSection';
import { HowItWorksSection } from './landing/HowItWorksSection';
import { WhyChooseSection } from './landing/WhyChooseSection';
import { EarlyAdopterSection } from './landing/EarlyAdopterSection';
import { TeamsSection } from './landing/TeamsSection';
import { CtaSection } from './landing/CtaSection';

export default function Landing() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const showSuccessBanner = searchParams.get('success') === 'true';

  if (user) {
    return (
      <>
        {showSuccessBanner && <SuccessBanner />}
        <div className="min-h-screen bg-gradient-to-b from-sky-100 to-indigo-50">
        <div className="flex flex-col items-center justify-center min-h-[75vh] p-4">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-center max-w-4xl w-full"
          >
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: [0, -10, 0] }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="relative mb-6"
            >
              <div className="absolute inset-0 blur-xl bg-blue-200/50 rounded-full" />
              <div className="relative text-8xl md:text-9xl">
                🫐
              </div>
            </motion.div>

            <h1 className="text-4xl md:text-5xl font-bold text-indigo-900 mb-4">
              Finding Blueberries
            </h1>
            
            <p className="text-lg text-indigo-700 mb-8 max-w-md mx-auto">
              A delightful journey to discover your path to success.
            </p>

            <div className="flex flex-col items-center space-y-4">
              <motion.button
                onClick={() => navigate('/clarity-kick-start')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
              >
                <Sparkles className="w-6 h-6 transition-transform group-hover:rotate-6" />
                <span>Unsure? Start Here!</span>
                <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
              </motion.button>
              
              <motion.button
                onClick={() => navigate('/deeper-reflections')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
              >
                <Brain className="w-6 h-6 transition-transform group-hover:rotate-6" />
                <span>Deeper Reflections</span>
                <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
              </motion.button>
              
              <motion.button
                onClick={() => navigate('/shared-experiences')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
              >
                <Users className="w-6 h-6 transition-transform group-hover:rotate-6" />
                <span>Shared Reflections</span>
                <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
              </motion.button>
              
              <motion.button
                onClick={() => navigate('/daily-reflection')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
              >
                <CalendarDays className="w-6 h-6 transition-transform group-hover:rotate-6" />
                <span>Daily Reflection</span>
                <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
              </motion.button>
            </div>
          </motion.div>
        </div>
       </div>
      </>
    );
  }

  return (
    <>
      {showSuccessBanner && <SuccessBanner />}
      <HeroSection onSignUp={() => navigate('/login')} />
      <PainSection />
      <BenefitsSection onSignUp={() => navigate('/login')} />
      <HowItWorksSection />
      <WhyChooseSection onSignUp={() => navigate('/login')} />
      <EarlyAdopterSection />
      <TeamsSection />
      <CtaSection onSignUp={() => navigate('/login')} />
    </>
  );
}