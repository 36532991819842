import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageSquare, ChevronDown, ChevronUp } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

interface ThemeSummaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  theme: {
    title: string;
    questions: Array<{
      id: number;
      text: string;
    }>;
  };
  summary: string;
  answers: Record<number, string>;
}

export default function ThemeSummaryModal({
  isOpen,
  onClose,
  theme,
  summary,
  answers
}: ThemeSummaryModalProps) {
  const [showResponses, setShowResponses] = React.useState(false);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 z-50"
          >
            <div className="bg-white rounded-xl max-w-4xl w-full p-8 relative max-h-[90vh] overflow-y-auto">
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>

              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-900">Theme Summary</h3>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">{theme.title} Summary</h2>
                <button
                  onClick={() => setShowResponses(!showResponses)}
                  className="w-full flex items-center justify-between p-4 bg-purple-50 rounded-lg hover:bg-purple-100 transition-colors"
                >
                  <div className="flex items-center space-x-2">
                    <MessageSquare className="w-5 h-5 text-purple-600" />
                    <span className="font-medium text-purple-900">Your Responses</span>
                  </div>
                  {showResponses ? (
                    <ChevronUp className="w-5 h-5 text-purple-600" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-purple-600" />
                  )}
                </button>
                
                {showResponses && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    className="mt-4 space-y-4 bg-white p-6 rounded-lg border border-purple-100"
                  >
                    {theme.questions.map((question) => (
                      <div key={question.id} className="space-y-2">
                        <p className="font-medium text-purple-900">{question.text}</p>
                        <p className="text-gray-600 whitespace-pre-wrap">{answers[question.id]}</p>
                      </div>
                    ))}
                  </motion.div>
                )}
              </div>

              <div className="prose max-w-none [&>ul]:list-none [&>ul]:pl-0">
                <ReactMarkdown>{summary}</ReactMarkdown>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}