import React, { useState, useEffect } from 'react';
import { ArrowUpDown, FileText, Trash2 } from 'lucide-react';
import { collection, query, orderBy, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import ReactMarkdown from 'react-markdown';

interface UserAction {
  id: string;
  userId: string;
  userName: string;
  actionType: 'theme_start' | 'theme_complete' | 'summary_generated';
  themeId: string;
  themeName: string;
  timestamp: string;
  summary?: string;
}

interface SortConfig {
  key: keyof UserAction;
  direction: 'asc' | 'desc';
}

export default function UserActivity() {
  const [actions, setActions] = useState<UserAction[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'timestamp', direction: 'desc' });
  const [selectedSummary, setSelectedSummary] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<string>('all');
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchUserActions();
  }, []);

  const fetchUserActions = async () => {
    try {
      const actionsRef = collection(db, 'userActions');
      const q = query(actionsRef, orderBy('timestamp', 'desc'));
      const snapshot = await getDocs(q);
      const fetchedActions = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as UserAction[];
      setActions(fetchedActions);
    } catch (error) {
      console.error('Error fetching user actions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key: keyof UserAction) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedActions = [...actions].sort((a, b) => {
    if (sortConfig.direction === 'asc') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    }
    return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
  });

  const filteredActions = filterType === 'all' 
    ? sortedActions 
    : sortedActions.filter(action => action.actionType === filterType);

  const handleSelectAll = () => {
    if (selectedActions.length === filteredActions.length) {
      setSelectedActions([]);
    } else {
      setSelectedActions(filteredActions.map(action => action.id));
    }
  };

  const handleSelectAction = (actionId: string) => {
    setSelectedActions(prev => 
      prev.includes(actionId)
        ? prev.filter(id => id !== actionId)
        : [...prev, actionId]
    );
  };

  const handleDeleteSelected = async () => {
    if (!window.confirm(`Are you sure you want to delete ${selectedActions.length} selected items?`)) {
      return;
    }

    setDeleteLoading(true);
    try {
      await Promise.all(
        selectedActions.map(actionId =>
          deleteDoc(doc(db, 'userActions', actionId))
        )
      );
      
      setActions(prev => prev.filter(action => !selectedActions.includes(action.id)));
      setSelectedActions([]);
    } catch (error) {
      console.error('Error deleting actions:', error);
      alert('Error deleting selected items');
    } finally {
      setDeleteLoading(false);
    }
  };

  const actionTypeLabels = {
    theme_start: 'Theme Started',
    theme_complete: 'Theme Completed',
    summary_generated: 'Summary Generated'
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-900">User Activity</h2>
        <div className="flex items-center space-x-4">
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="all">All Actions</option>
            <option value="theme_start">Theme Started</option>
            <option value="theme_complete">Theme Completed</option>
            <option value="summary_generated">Summary Generated</option>
          </select>
          {selectedActions.length > 0 && (
            <button
              onClick={handleDeleteSelected}
              disabled={deleteLoading}
              className="flex items-center space-x-2 px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors disabled:opacity-50"
            >
              <Trash2 className="w-4 h-4" />
              <span>Delete Selected ({selectedActions.length})</span>
            </button>
          )}
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  checked={selectedActions.length === filteredActions.length}
                  onChange={handleSelectAll}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </th>
              {['User', 'Action', 'Theme', 'Time'].map((header, index) => (
                <th
                  key={header}
                  onClick={() => handleSort(['userName', 'actionType', 'themeName', 'timestamp'][index] as keyof UserAction)}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                >
                  <div className="flex items-center space-x-1">
                    <span>{header}</span>
                    <ArrowUpDown className="w-4 h-4" />
                  </div>
                </th>
              ))}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Summary
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredActions.map((action) => (
              <tr key={action.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={selectedActions.includes(action.id)}
                    onChange={() => handleSelectAction(action.id)}
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {action.userName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    action.actionType === 'theme_start' ? 'bg-blue-100 text-blue-800' :
                    action.actionType === 'theme_complete' ? 'bg-green-100 text-green-800' :
                    'bg-purple-100 text-purple-800'
                  }`}>
                    {actionTypeLabels[action.actionType]}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {action.themeName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(action.timestamp).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {action.summary && (
                    <button
                      onClick={() => setSelectedSummary(action.summary)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <FileText className="w-5 h-5" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedSummary && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-2xl w-full p-6 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-900">Theme Summary</h3>
              <button
                onClick={() => setSelectedSummary(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
            <div className="prose prose-indigo max-w-none">
              <ReactMarkdown>{selectedSummary}</ReactMarkdown>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}