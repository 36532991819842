// src/components/landing/CtaSection.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

interface CtaSectionProps {
  onSignUp: () => void;
}

export function CtaSection({ onSignUp }: CtaSectionProps) {
  const navigate = useNavigate();

  return (
    <div className="bg-gradient-to-b from-indigo-50 to-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto text-center"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
            Start Your Journey Today.
          </h2>
          
          <p className="text-xl text-gray-600 mb-8">
            Sign up now to take your first deep dive reflection, begin daily reflections, 
            or share a value summary with someone who inspires you.
          </p>

          <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-6 mb-12">
            <motion.button
              onClick={() => navigate('/purchase')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group flex items-center space-x-3 px-8 py-4 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-blue-700 hover:to-indigo-700 transition-all duration-200"
            >
              <span>Get Started</span>
              <ArrowRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
            </motion.button>

            <a 
              href="mailto:brian@findinggood.com"
              className="text-indigo-600 hover:text-indigo-700 font-medium"
            >
              Contact Us for Teams
            </a>
          </div>

          <p className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
            The World Needs Your Value. Let FindingBlueberries Help You See It.
          </p>
        </motion.div>
      </div>
    </div>
  );
}
