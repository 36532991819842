import React, { useState, useRef } from 'react';
import { toast } from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { TextInput } from './TextInput';
import { SplitAnalyzeButton } from './SplitAnalyzeButton';
import { generateStructuredInfo } from '../lib/openai';
import type { AIConfig } from '../App';

const EXAMPLE_TEXT = `Week 1 Accomplishments and Personal Pride
Theme Introduction: "This week, weʼre
celebrating your accomplishments and moments of pride. Reflecting on these achievements helps you recognize your strengths and build a foundation of confidence for the year ahead."
Jamieʼs Responses for Week 1
Day 1 What was your biggest accomplishment this year?
Leading a major software launch that was critical for the companyʼs growth. I managed a team of ten people, and despite some unexpected obstacles, we launched on time. It felt like my biggest professional success so far.
Day 2 What action are you most proud of taking this year, regardless of the outcome?
Stepping up to mentor a junior colleague who was struggling with confidence. It didnʼt directly benefit my role, but I saw potential in her and wanted to help. Seeing her grow over the months was very fulfilling, even if it took extra time out of my schedule.
Example Process and Summaries 1
Day 3 What day did you feel most proud of yourself this year?
The day I spoke at our companyʼs annual retreat. Public speaking isnʼt my strength, but I took the opportunity to share what our team had achieved with the company. It was nerve-wracking, but afterward, I felt proud for facing my fear.
Day 4 What was the one thing you did that had the biggest positive impact on someone else?
Coaching my junior colleague to take on her own project. She was initially unsure, but she took the plunge, and her confidence has skyrocketed since then. Sheʼs now seen as a valuable team member by others, too.
Day 5 When was your happiest moment this year?
When my sister and I went on a weekend getaway together. It was the first time weʼd taken a trip like that in years, and we laughed like we did when we were kids. It reminded me of the importance of reconnecting.`;

const DEFAULT_SYSTEM_PROMPT = `Please take the data provided and generate a summary for the user in story form that is inspiring and concise. Please return the data in markdown so titles and bullets can be nicely shown by the app. There should be an h1 title for the summary (eg Jamie's Journey of Growth and Achievement) and each section should have an h2 title.

Always return the same structured categories. Story summary is a paragraph of text and can, but doesn't need, to have a few bullets. 2, 3 and 4 are 2-5 bullets each. 5 is a paragraph.

1. Story Summary
A concise narrative overview of the journey, challenges faced, and growth achieved.

2. Highlights and Wins
Key accomplishments and positive moments, formatted as bullet points.

3. Strengths and Skills Used
Core competencies and abilities demonstrated throughout the journey, as bullet points.

4. Impact of Actions
The effects of decisions and actions on both personal and professional life.

5. Potential Future Story
A forward-looking paragraph that builds on the insights and growth shown.

Format your response using markdown headers:
# for the main title (e.g., "# Jamie's Journey of Growth and Achievement")
## for each section (e.g., "## Story Summary")
- for bullet points (e.g., "- Mastered coding while working full-time")

Make the tone inspiring and personal while maintaining professionalism.`;

export default function TextAnalyzer({ initialText, reflections, getFormattedReflections }: TextAnalyzerProps) {
  const [inputText, setInputText] = useState(initialText);
  const [systemPrompt, setSystemPrompt] = useState(DEFAULT_SYSTEM_PROMPT);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<string | null>(null);
  const [debugData, setDebugData] = useState<{ request: any; response: any } | null>(null);
  const [showDebug, setShowDebug] = useState(false);
  const [aiConfig, setAIConfig] = useState<AIConfig>({
    model: 'gpt-3.5-turbo',
    temperature: 0.7
  });

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleLoadReflections = () => {
    const formattedText = getFormattedReflections();
    setInputText(formattedText);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleLoadExample = () => {
    setInputText(EXAMPLE_TEXT);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleAnalyze = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputText.trim()) return;

    setLoading(true);
    setError(null);
    setResult(null);
    setDebugData(null);

    const requestData = {
      messages: [
        { role: 'system', content: systemPrompt },
        { role: 'user', content: inputText }
      ],
      model: aiConfig.model,
      temperature: aiConfig.temperature,
    };

    try {
      const response = await generateStructuredInfo(inputText, aiConfig, systemPrompt);
      setResult(response.data);
      setDebugData({ request: requestData, response });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-6 space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-900">AI Analysis</h2>
          <div className="flex space-x-4">
            <button
              onClick={handleLoadExample}
              className="px-4 py-2 text-sm bg-purple-100 hover:bg-purple-200 text-purple-700 rounded-lg transition-colors"
            >
              Load Example
            </button>
            <button
              onClick={handleLoadReflections}
              className="px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors"
            >
              Load My Story
            </button>
          </div>
        </div>
        
        <p className="text-gray-600">
          Let AI analyze your story and provide structured insights.
        </p>
      </div>

      <div className="space-y-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            System Prompt
          </label>
          <textarea
            value={systemPrompt}
            onChange={(e) => setSystemPrompt(e.target.value)}
            rows={4}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 font-mono text-sm"
            placeholder="Enter system prompt..."
          />
        </div>

        <TextInput
          ref={inputRef}
          inputText={inputText}
          onInputChange={setInputText}
          loading={loading}
        />

        <SplitAnalyzeButton
          onAnalyze={handleAnalyze}
          loading={loading}
          disabled={!inputText.trim()}
          config={aiConfig}
          onConfigChange={setAIConfig}
          label="Analyze Story"
        />

        {loading && (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600 mx-auto"></div>
            <p className="mt-4 text-gray-600">Analyzing your story...</p>
          </div>
        )}

        {result && (
          <div className="bg-white rounded-lg shadow-sm p-6">
            <article className="prose prose-purple max-w-none">
              <ReactMarkdown>{result}</ReactMarkdown>
            </article>
          </div>
        )}

        {error && (
          <div className="bg-red-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-red-800 mb-2">Something went wrong</h3>
            <p className="text-red-700">{error}</p>
          </div>
        )}

        {debugData && (
          <div className="mt-8 bg-gray-50 rounded-lg border border-gray-200">
            <button
              onClick={() => setShowDebug(!showDebug)}
              className="w-full px-6 py-4 flex items-center justify-between text-left hover:bg-gray-100 transition-colors rounded-lg"
            >
              <span className="text-sm font-medium text-gray-700">Debug Data</span>
              {showDebug ? (
                <ChevronUp className="w-5 h-5 text-gray-500" />
              ) : (
                <ChevronDown className="w-5 h-5 text-gray-500" />
              )}
            </button>
            
            {showDebug && (
              <div className="p-6 space-y-6 border-t border-gray-200">
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">API Request</h4>
                  <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg overflow-x-auto">
                    {JSON.stringify(debugData.request, null, 2)}
                  </pre>
                </div>
                
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">API Response</h4>
                  <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg overflow-x-auto">
                    {JSON.stringify(debugData.response, null, 2)}
                  </pre>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}