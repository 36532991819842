import { collection, query, where, orderBy, getDocs, doc, getDoc, addDoc } from 'firebase/firestore';
import { db } from './config';
import type { Theme, CompletedTheme } from './types';
import { toast } from 'react-hot-toast';

// In-memory cache
const themeCache = new Map<string, Theme>();
const themeTitlesCache = new Map<string, string>();

/**
 * Get theme by ID
 */
export const getThemeById = async (themeId: string): Promise<Theme | null> => {
  try {
    // Check cache first
    if (themeCache.has(themeId)) {
      return themeCache.get(themeId)!;
    }

    const themesRef = collection(db, 'themes');
    const q = query(themesRef, where('__name__', '==', themeId));
    const snapshot = await getDocs(q);
    
    if (!snapshot.empty) {
      const themeDoc = snapshot.docs[0];
      const theme = {
        id: themeDoc.id,
        ...themeDoc.data()
      } as Theme;
      
      // Cache the theme
      themeCache.set(themeId, theme);
      return theme;
    }
    return null;
  } catch (error) {
    console.error('Error getting theme:', error);
    return null;
  }
};

/**
 * Get theme title by ID with caching
 */
export const getThemeTitle = async (themeId: string): Promise<string> => {
  try {
    // Check cache first
    if (themeTitlesCache.has(themeId)) {
      return themeTitlesCache.get(themeId)!;
    }

    const theme = await getThemeById(themeId);
    if (theme?.title) {
      themeTitlesCache.set(themeId, theme.title);
      return theme.title;
    }
    return 'Theme';
  } catch (error) {
    console.error('Error getting theme title:', error);
    return 'Theme';
  }
};
/**
 * Get themes by experience ID
 */
export const getThemesByExperience = async (experienceId: string): Promise<Theme[]> => {
  try {
    const themesRef = collection(db, 'themes');
    const q = query(
      themesRef,
      where('experienceId', '==', experienceId),
      orderBy('order', 'asc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as Theme));
  } catch (error) {
    console.error('Error getting themes:', error);
    return [];
  }
};

/**
 * Save a completed theme
 */
export const saveCompletedTheme = async (theme: CompletedTheme, currentTheme: Theme) => {
  try {
    console.log('Starting theme completion...', {
      themeId: theme.themeId,
      userId: theme.userId,
      responsesCount: theme.responses.length
    });

    console.log('Saving theme with data:', { theme, currentTheme });

    // Validate required fields
    const requiredFields = ['userId', 'themeId', 'experienceId', 'responses', 'summary'];
    const missingFields = requiredFields.filter(field => !theme[field]);
    
    if (missingFields.length > 0) {
      const error = `Missing required fields: ${missingFields.join(', ')}`;
      console.error(error, theme);
      throw new Error(error);
    }

    // Validate summary content
    if (!theme.summary || typeof theme.summary !== 'string' || !theme.summary.trim()) {
      console.error('Invalid or empty summary:', theme.summary);
      throw new Error('Summary is required and must be non-empty');
    }

    // Create the completed theme document with explicit title fields
    const completedTheme = {
      ...theme,
      themeTitle: currentTheme.title || await getThemeTitle(theme.themeId),
      summaryTitle: theme.themeType === 'experience_summary' ? 'Year In Review Summary' : `${currentTheme.title} Summary`,
      questions: currentTheme.questions
    };

    console.log('Saving completed theme:', {
      themeId: theme.themeId,
      themeTitle: completedTheme.themeTitle,
      summaryTitle: completedTheme.summaryTitle
    });

    const docRef = await addDoc(collection(db, 'completedThemes'), completedTheme);
    console.log('Theme saved successfully:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error saving completed theme:', error);
    toast.error('Failed to save theme');
    throw error;
  }
};

/**
 * Get completed themes for a user
 */
export const getCompletedThemes = async (userId: string): Promise<CompletedTheme[]> => {
  try {
    if (!userId) {
      throw new Error('User ID is required');
    }

    const q = query(
      collection(db, 'completedThemes'),
      where('userId', '==', userId),
      orderBy('completedAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    const themes = snapshot.docs.map(doc => {
      const data = doc.data() as CompletedTheme;
      
      // Cache theme title if available
      if (data.themeTitle) {
        themeTitlesCache.set(data.themeId, data.themeTitle);
      }
      
      return {
        id: doc.id,
        ...data
      };
    });
    
    // Load missing titles in parallel
    const themesNeedingTitles = themes.filter(t => !t.themeTitle);
    if (themesNeedingTitles.length > 0) {
      await Promise.all(
        themesNeedingTitles.map(async theme => {
          const title = await getThemeTitle(theme.themeId);
          theme.themeTitle = title;
          theme.summaryTitle = theme.themeType === 'experience_summary' 
            ? 'Year In Review Summary' 
            : `${title} Summary`;
        })
      );
    }
    
    return themes;
  } catch (error) {
    console.error('Error getting completed themes:', error);
    throw error;
  }
};