import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Download, ArrowUpDown, Trash2 } from 'lucide-react';
import { jsPDF } from 'jspdf';
import { getThemeInfo, getThemeTitle } from '../../lib/themeUtils';
import ReactMarkdown from 'react-markdown';
import { toast } from 'react-hot-toast';

interface CompletedTheme {
  id: string;
  userId: string;
  userName: string;
  themeId: string;
  experienceId: string;
  completedAt: string;
  summary: string;
}

export default function CompletedThemes() {
  const [themes, setThemes] = useState<CompletedTheme[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTheme, setSelectedTheme] = useState<CompletedTheme | null>(null);
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [showOnlyExperienceSummaries, setShowOnlyExperienceSummaries] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof CompletedTheme;
    direction: 'asc' | 'desc';
  }>({ key: 'completedAt', direction: 'desc' });

  useEffect(() => {
    const loadThemes = async () => {
      try {
        setLoading(true);
        const q = query(
          collection(db, 'completedThemes'),
          orderBy('completedAt', 'desc')
        );
        
        const snapshot = await getDocs(q);
        const fetchedThemes = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as CompletedTheme[];
        
        setThemes(fetchedThemes);
      } catch (error) {
        console.error('Error loading completed themes:', error);
        setError('Failed to load completed themes');
      } finally {
        setLoading(false);
      }
    };

    loadThemes();
  }, []);

  const handleSort = (key: keyof CompletedTheme) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedThemes = [...themes].sort((a, b) => {
    if (sortConfig.direction === 'asc') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    }
    return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
  });

  const filteredThemes = sortedThemes.filter(theme => !showOnlyExperienceSummaries || theme.themeType === 'experience_summary');

  const downloadPDF = (theme: CompletedTheme) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 20;
    let yPos = margin;
    const timestamp = new Date().toLocaleString();
    const title = theme.themeType === 'experience_summary' ? 'Year In Review' : getThemeTitle(theme.themeId);

    doc.setFontSize(24);
    doc.text(`${title} - Summary`, pageWidth / 2, yPos, { align: 'center' });
    yPos += 20;

    // User and Date
    doc.setFontSize(12);
    doc.setTextColor(128, 128, 128);
    doc.text(`Generated for ${theme.userName}`, margin, yPos);
    doc.text(timestamp, pageWidth - margin, yPos, { align: 'right' });
    yPos += 10;

    doc.setFontSize(12);
    const lines = doc.splitTextToSize(theme.summary, pageWidth - (margin * 2));
    doc.text(lines, margin, yPos);

    // Footer
    doc.setFontSize(10);
    doc.setTextColor(128, 128, 128);
    const footer = 'Visit findingblueberries.com to try for yourself';
    const footerLink = 'https://findingblueberries.com';
    const footerY = pageHeight - margin;
    doc.text(footer, pageWidth / 2, footerY, { align: 'center' });
    doc.setTextColor(124, 58, 237); // Purple color for link
    doc.textWithLink('findingblueberries.com', pageWidth / 2 - 20, footerY + 5, { url: footerLink });

    const fileName = `${title.toLowerCase().replace(/\s+/g, '-')}-summary-${timestamp.replace(/[/:]/g, '-')}.pdf`;
    doc.save(fileName);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-purple-200 border-t-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <p className="text-red-700">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <h2 className="text-xl font-semibold text-gray-900">
              Completed Themes ({themes.length})
            </h2>
            {selectedThemes.length > 0 && (
              <button
                onClick={async () => {
                  if (!window.confirm(`Are you sure you want to delete ${selectedThemes.length} selected items?`)) {
                    return;
                  }
                  setLoading(true);
                  try {
                    await Promise.all(
                      selectedThemes.map(id => 
                        deleteDoc(doc(db, 'completedThemes', id))
                      )
                    );
                    setThemes(prev => prev.filter(theme => !selectedThemes.includes(theme.id)));
                    setSelectedThemes([]);
                    toast.success('Selected themes deleted successfully');
                  } catch (error) {
                    console.error('Error deleting themes:', error);
                    toast.error('Failed to delete selected themes');
                  } finally {
                    setLoading(false);
                  }
                }}
                className="flex items-center space-x-2 px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors"
              >
                <Trash2 className="w-4 h-4" />
                <span>Delete Selected ({selectedThemes.length})</span>
              </button>
            )}
          </div>
          <button
            onClick={() => setShowOnlyExperienceSummaries(!showOnlyExperienceSummaries)}
            className={`px-4 py-2 rounded-lg text-sm transition-colors ${
              showOnlyExperienceSummaries
                ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {showOnlyExperienceSummaries ? 'Show All' : 'Show Experience Summaries'}
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  checked={selectedThemes.length === filteredThemes.length}
                  onChange={() => {
                    if (selectedThemes.length === filteredThemes.length) {
                      setSelectedThemes([]);
                    } else {
                      setSelectedThemes(filteredThemes.map(theme => theme.id));
                    }
                  }}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </th>
              <th
                onClick={() => handleSort('userName')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>User</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th
                onClick={() => handleSort('themeId')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Theme</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th
                onClick={() => handleSort('experienceId')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Experience</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th
                onClick={() => handleSort('completedAt')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Completed</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredThemes.map((theme) => (
              <tr 
                key={theme.id} 
                className={`hover:bg-gray-50 ${
                  theme.themeType === 'experience_summary' ? 'bg-indigo-50/30' : ''
                }`}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={selectedThemes.includes(theme.id)}
                    onChange={() => {
                      setSelectedThemes(prev => 
                        prev.includes(theme.id)
                          ? prev.filter(id => id !== theme.id)
                          : [...prev, theme.id]
                      );
                    }}
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {theme.userName}
                  </div>
                  <div className="text-sm text-gray-500">
                    {theme.userId}
                    {theme.themeType === 'experience_summary' && (
                      <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                        Experience Summary
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {getThemeTitle(theme.themeId)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {theme.experienceId === 'shared-experiences' || theme.experienceId === 'FkfQnHvmsEdjfME9n3sS' ? 'Shared Experiences' : 'Year In Review'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(theme.completedAt).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => setSelectedTheme(theme)}
                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                  >
                    View
                  </button>
                  <button
                    onClick={() => downloadPDF(theme)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    <Download className="w-4 h-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedTheme && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-3xl w-full p-6 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                {selectedTheme.themeType === 'experience_summary' ? 'Year In Review' : getThemeTitle(selectedTheme.themeId)} Summary
              </h3>
              <button
                onClick={() => setSelectedTheme(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                ×
              </button>
            </div>
            <div className="prose prose-indigo max-w-none">
              <ReactMarkdown>{selectedTheme.summary}</ReactMarkdown>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}