import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { GripVertical } from 'lucide-react';
import { toast } from 'react-hot-toast';
import type { Experience } from '../../lib/firebase/types';

export default function DeepDives() {
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchExperiences();
  }, []);

  const fetchExperiences = async () => {
    try {
      setLoading(true);
      const q = query(collection(db, 'experiences'), orderBy('deepDiveOrder', 'asc'));
      const snapshot = await getDocs(q);
      const experiencesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Experience[];
      
      setExperiences(experiencesData);
    } catch (error) {
      console.error('Error fetching experiences:', error);
      toast.error('Failed to load experiences');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleDeepDive = async (experience: Experience) => {
    try {
      const experienceRef = doc(db, 'experiences', experience.id);
      const showInDeepDives = !experience.showInDeepDives;
      
      await updateDoc(experienceRef, {
        showInDeepDives,
        deepDiveOrder: showInDeepDives ? (Math.max(...experiences.map(e => e.deepDiveOrder || 0)) + 1) : null
      });
      
      await fetchExperiences();
      toast.success(`${experience.title} ${showInDeepDives ? 'added to' : 'removed from'} Deep Dives`);
    } catch (error) {
      console.error('Error updating experience:', error);
      toast.error('Failed to update experience');
    }
  };

  const handleReorder = async (draggedId: string, targetId: string) => {
    try {
      const draggedExp = experiences.find(e => e.id === draggedId);
      const targetExp = experiences.find(e => e.id === targetId);
      
      if (!draggedExp || !targetExp) return;
      
      const updates = experiences
        .filter(e => e.showInDeepDives)
        .sort((a, b) => (a.deepDiveOrder || 0) - (b.deepDiveOrder || 0))
        .map((exp, index) => ({
          id: exp.id,
          deepDiveOrder: index + 1
        }));
      
      // Swap orders
      const draggedIndex = updates.findIndex(u => u.id === draggedId);
      const targetIndex = updates.findIndex(u => u.id === targetId);
      [updates[draggedIndex].deepDiveOrder, updates[targetIndex].deepDiveOrder] = 
      [updates[targetIndex].deepDiveOrder, updates[draggedIndex].deepDiveOrder];
      
      // Update all experiences
      await Promise.all(
        updates.map(update => 
          updateDoc(doc(db, 'experiences', update.id), {
            deepDiveOrder: update.deepDiveOrder
          })
        )
      );
      
      await fetchExperiences();
    } catch (error) {
      console.error('Error reordering experiences:', error);
      toast.error('Failed to reorder experiences');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  const deepDiveExperiences = experiences
    .filter(e => e.showInDeepDives)
    .sort((a, b) => (a.deepDiveOrder || 0) - (b.deepDiveOrder || 0));

  const otherExperiences = experiences
    .filter(e => !e.showInDeepDives)
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-xl font-semibold text-gray-900">Deep Dive Experiences</h2>
        <p className="mt-2 text-gray-600">
          Select which experiences to show on the Deep Dives page and arrange their order.
        </p>
      </div>

      <div className="p-6">
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Currently Showing</h3>
          <div className="space-y-2">
            {deepDiveExperiences.length === 0 ? (
              <p className="text-gray-500 italic">No experiences selected for Deep Dives</p>
            ) : (
              deepDiveExperiences.map((experience) => (
                <div
                  key={experience.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                  draggable
                  onDragStart={(e) => e.dataTransfer.setData('text/plain', experience.id)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const draggedId = e.dataTransfer.getData('text/plain');
                    handleReorder(draggedId, experience.id);
                  }}
                >
                  <div className="flex items-center space-x-4">
                    <GripVertical className="w-5 h-5 text-gray-400 cursor-move" />
                    <div>
                      <h4 className="font-medium text-gray-900">{experience.title}</h4>
                      <p className="text-sm text-gray-500">{experience.description}</p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleToggleDeepDive(experience)}
                    className="px-3 py-1 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg"
                  >
                    Remove
                  </button>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Available Experiences</h3>
          <div className="space-y-2">
            {otherExperiences.map((experience) => (
              <div
                key={experience.id}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
              >
                <div>
                  <h4 className="font-medium text-gray-900">{experience.title}</h4>
                  <p className="text-sm text-gray-500">{experience.description}</p>
                </div>
                <button
                  onClick={() => handleToggleDeepDive(experience)}
                  className="px-3 py-1 text-sm text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg"
                >
                  Add to Deep Dives
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}