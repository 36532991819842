import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  ArrowRight,
  Brain,
  Target,
  Heart,
  Compass,
  Sparkles,
} from 'lucide-react';

interface WhyChooseSectionProps {
  onSignUp: () => void;
}

export function WhyChooseSection({ onSignUp }: WhyChooseSectionProps) {
  const navigate = useNavigate();

  const benefits = [
    {
      icon: Brain,
      title: 'Emotional Intelligence',
      description: 'Discover the impact on others people often miss.',
    },
    {
      icon: Sparkles,
      title: 'Growth Mindset',
      description: 'Uncover your patterns for overcoming challenges.',
    },
    {
      icon: Target,
      title: 'Influence',
      description: "Focus on what's within your power to change.",
    },
    {
      icon: Compass,
      title: 'Purpose',
      description: 'Clarify values that guide your decisions.',
    },
    {
      icon: Heart,
      title: 'Strengths',
      description:
        'Identify the skills and abilities that come naturally to you.',
    },
  ];

  return (
    <div className="bg-gradient-to-b from-indigo-50 to-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Why Choose FindingBlueberries?
          </h2>
          <p className="text-xl text-gray-600">
            Built on Science. Designed for Connection.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8 mb-12">
          {benefits.map((benefit, index) => {
            const Icon = benefit.icon;
            return (
              <motion.div
                key={benefit.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-white p-6 rounded-xl shadow-lg"
              >
                <div className="flex items-center space-x-4 mb-4">
                  <div className="p-2 bg-indigo-100 rounded-lg">
                    <Icon className="w-5 h-5 text-indigo-600" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {benefit.title}
                  </h3>
                </div>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            );
          })}
        </div>

        <div className="text-center">
          <p className="text-xl text-indigo-900 mb-8">
            When you see yourself clearly, you connect with the world
            authentically.
          </p>

          <motion.button
            onClick={() => navigate('/purchase')}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="group inline-flex items-center space-x-3 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200"
          >
            <span>Join Now</span>
            <ArrowRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
          </motion.button>
        </div>
      </div>
    </div>
  );
}
