import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, orderBy, getDocs, doc, getDoc, addDoc, limit } from 'firebase/firestore';
import { db, getExperiences } from '../../lib/firebase';
import type { Experience } from '../../lib/firebase/types';
import type { Theme } from '../../lib/firebase/types';
import { toast } from 'react-hot-toast';
import ThemeOverview from '../YourStory/ThemeOverview';
import QuestionModal from '../YourStory/QuestionModal';
import ThemeSummaryModal from '../YearInReview/ThemeSummaryModal';
import ExperienceIntro from '../shared/ExperienceIntro';
import LockedThemeModal from '../shared/LockedThemeModal';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { generateStructuredInfo } from '../../lib/openai';
import ZenLoader from '../YearInReview/ZenLoader';
import { FileText, ChevronDown, ChevronUp, Share2 } from 'lucide-react';
import ThemeSummaryViewer from '../shared/ThemeSummaryViewer';
import type { CompletedTheme } from '../../lib/firebase/types';

export default function SharedExperiences() {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [experience, setExperience] = useState<Experience | null>(null);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [loading, setLoading] = useState(true);
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);
  const [showThemeOverview, setShowThemeOverview] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [themeContent, setThemeContent] = useState<{ [key: string]: any }>({});
  const [currentDay, setCurrentDay] = useState(1);
  const [showThemeGrid, setShowThemeGrid] = useState(true);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [recentCompletions, setRecentCompletions] = useState<CompletedTheme[]>([]); 
  const [themeTitles, setThemeTitles] = useState<Record<string, string>>({});
  const [expandedThemeId, setExpandedThemeId] = useState<string | null>(null);
  const [isGeneratingExperienceSummary, setIsGeneratingExperienceSummary] = useState(false);
  const [createdFor, setCreatedFor] = useState<string>('');
  const [experienceSummary, setExperienceSummary] = useState<CompletedTheme | null>(null);
  const [hasAllThemesCompleted, setHasAllThemesCompleted] = useState(false);
  const [showNewSummaryViewer, setShowNewSummaryViewer] = useState(false);
  const [currentContent, setCurrentContent] = useState('');

  useEffect(() => {
    const loadExperience = async () => {
      try {
        console.log('Loading shared experiences...');
        setLoading(true);
        const completionsRef = collection(db, 'completedThemes');

        if (user) {
          // Load recent completions
          const completionsQuery = query(
            completionsRef,
            where('userId', '==', user.uid),
            where('experienceId', '==', 'shared-experiences'), // Use route as ID for now
            orderBy('completedAt', 'desc'),
            limit(10)
          );
          const completionsSnapshot = await getDocs(completionsQuery);
          const completions = completionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as CompletedTheme[];
          setRecentCompletions(completions);
          console.log('Loaded recent completions:', completions);
          setRecentCompletions(completions);
        }

        // Query directly for the shared experiences route
        const experiencesRef = collection(db, 'experiences');
        console.log('Querying for route:', 'shared-experiences');
        
        // First get the experience by route
        const routeQuery = query(experiencesRef, where('route', '==', 'shared-experiences'));
        const routeSnapshot = await getDocs(routeQuery);
        
        if (!routeSnapshot.empty && user) {
          const experienceDoc = routeSnapshot.docs[0];
          const experienceId = experienceDoc.id;
        
          // Update completions query with correct experience ID
          const completionsQuery = query(
            completionsRef,
            where('userId', '==', user.uid),
            where('experienceId', '==', experienceId),
            orderBy('completedAt', 'desc'),
            limit(10)
          );
          const completionsSnapshot = await getDocs(completionsQuery);
          const completions = completionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as CompletedTheme[];
          setRecentCompletions(completions);
          console.log('Loaded recent completions:', completions);
        }
        
        if (!routeSnapshot.empty) {
          const experienceDoc = routeSnapshot.docs[0];
          console.log('Found experience:', experienceDoc.id);
          const sharedExperience = {
            id: experienceDoc.id,
            ...experienceDoc.data()
          } as Experience;
          
          setExperience(sharedExperience);
          
          // Load themes
          const themesRef = collection(db, 'themes');
          const themesQuery = query(
            themesRef,
            where('experienceId', '==', sharedExperience.id),
            orderBy('order', 'asc')
          );
          const themesSnapshot = await getDocs(themesQuery);
          console.log('Found themes:', themesSnapshot.docs.length);
          console.log('Theme details:', themesSnapshot.docs.map(doc => ({
            id: doc.id,
            title: doc.data().title,
            experienceId: doc.data().experienceId
          })));
          
          const themesData = themesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as Theme[];
          setThemes(themesData);
        } else {
          console.error('No shared experiences found. Query returned empty. Looking for route: shared-experiences');
          toast.error('This experience is not available yet');
          navigate('/');
          return;
        }
      } catch (error) {
        console.error('Error loading shared experiences:', error, error instanceof Error ? error.message : '');
        toast.error('Unable to load experience. Please try again later.');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    loadExperience();
  }, [navigate, user]);

  const handleThemeSelect = (theme: Theme) => {
    // Check if previous themes are completed
    const previousThemes = themes.slice(0, themes.indexOf(theme));
    const allPreviousCompleted = previousThemes.every(t =>
      !!themeContent[t.id]?.completedAt
    );
    
    if (!allPreviousCompleted && themes.indexOf(theme) > 0) {
      setShowLockedModal(true);
      return;
    }
    
    setSelectedTheme(theme);
    setShowThemeOverview(true);
    setShowThemeGrid(false);
  };

  const handleStartTheme = () => {
    setShowThemeOverview(false);
    if (selectedTheme) {
      const existingContent = themeContent[selectedTheme.id]?.responses || {};
      const completedTheme = themeContent[selectedTheme.id];
      if (completedTheme?.completedAt) {
        setCurrentDay(6);
      } else {
        setCurrentDay(1);
        setCurrentContent('');
      }
    }
    setShowThemeGrid(false);
  };

  const handleGenerateSummary = async (weekContent: { [key: number]: string }) => {
    if (!selectedTheme || !user || !userData) return;
    
    setIsGeneratingSummary(true);
    
    try {
      // Format content for OpenAI
      const content = Object.entries(weekContent)
        .sort(([a], [b]) => parseInt(a) - parseInt(b))
        .map(([day, content]) => {
          const question = selectedTheme.questions.find(q => q.id === parseInt(day));
          return `Question ${day}: ${question?.text}\nAnswer: ${content}`;
        })
        .join('\n\n');

      // Use theme's custom prompt if available, otherwise use default
      const systemPrompt = selectedTheme.systemPrompt || `Create an inspiring summary that highlights key themes, growth, and insights from the user's reflections. Structure the response in markdown format with these sections:

# Theme Journey
A concise narrative overview of the journey, challenges faced, and growth achieved.

## Highlights and Wins
Key accomplishments and positive moments (2-5 bullets)

## Strengths and Skills Used
Core competencies and abilities demonstrated throughout the journey (2-5 bullets)

## Impact of Actions
The effects of decisions and actions on both personal and professional life (2-5 bullets)

## Potential Future Story
A forward-looking paragraph that builds on the insights and growth shown.`;

      const response = await generateStructuredInfo(
        content,
        { model: 'gpt-4', temperature: 0.7 },
        systemPrompt + (createdFor ? `\n\nCreated for: ${createdFor}` : '')
      );

      const timestamp = new Date().toISOString();
      const completedTheme = {
        userId: user.uid,
        createdFor: createdFor.trim(),
        userName: `${userData.firstName} ${userData.lastName}`,
        themeId: selectedTheme.id,
        questions: selectedTheme.questions,
        themeType: 'theme',
        experienceId: experience?.id,
        experienceName: experience?.title,
        responses: Object.entries(weekContent).map(([questionId, content]) => ({
          questionId: parseInt(questionId),
          question: selectedTheme.questions.find(q => q.id === parseInt(questionId))?.text || '',
          content,
          createdAt: timestamp
        })),
        summary: response.data,
        completedAt: timestamp
      };

      const docRef = await addDoc(collection(db, 'completedThemes'), completedTheme);
      setThemeContent(prev => ({
        ...prev,
        [selectedTheme.id]: {
          responses: weekContent,
          summary: response.data,
          completedAt: timestamp
        }
      }));

      toast.success('Theme completed and saved!');
      setShowSummaryModal(false);
      setCurrentDay(5);
    } catch (error) {
      console.error('Error generating summary:', error);
      toast.error('Failed to generate summary');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const handleSaveResponse = async () => {
    if (!currentContent.trim() || !selectedTheme) return;
    setIsGeneratingSummary(true);

    try {
      const themeId = selectedTheme.id;
      const updatedTheme = {
        ...themeContent,
        [themeId]: {
          ...themeContent[themeId],
          responses: {
            ...(themeContent[themeId]?.responses || {}),
            [currentDay]: currentContent
          }
        }
      };
      setThemeContent(updatedTheme);

      if (currentDay === 5) {
        const responses = updatedTheme[themeId].responses;
        if (Object.keys(responses).length === 5) {
          await handleGenerateSummary(responses);
          setSelectedThemeId(themeId);
          setShowNewSummaryViewer(true);
          setShowThemeGrid(true);
          setSelectedTheme(null);
        } else {
          toast.error('Please complete all questions before generating summary');
        }
      } else {
        setCurrentDay(prev => prev + 1);
        setCurrentContent('');
      }
    } catch (error) {
      console.error('Error saving response:', error);
      toast.error('Failed to save response');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-purple-200 border-t-purple-600"></div>
          <p className="mt-4 text-purple-600">Loading experience...</p>
        </div>
      </div>
    );
  }

  if (!experience) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-purple-900 mb-4">Experience Not Found</h1>
            <p className="text-purple-600 mb-8">
              This experience is not available yet. Please check back later.
            </p>
            <button
              onClick={() => navigate('/')}
              className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Return Home
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {showThemeGrid ? (
          <>
            <div className="mb-8">
              <h1 className="text-3xl font-bold text-purple-900 mb-2">{experience.title}</h1>
              <p className="text-gray-600">{experience.description}</p>
              
              {recentCompletions.length > 0 && (
                <div className="mt-8">
                  <button
                    onClick={() => setExpandedThemeId(expandedThemeId ? null : 'recent')}
                    className="w-full flex items-center justify-between p-4 bg-blue-50/50 rounded-t-xl shadow-sm hover:bg-blue-50/70 transition-colors"
                  >
                    <div className="flex items-center space-x-2">
                      <h2 className="text-xl font-semibold text-purple-900">Shared Summaries</h2>
                      <span className="text-sm text-gray-500">({recentCompletions.length})</span>
                    </div>
                    {expandedThemeId === 'recent' ? (
                      <ChevronUp className="w-5 h-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-400" />
                    )}
                  </button>
                  
                  {expandedThemeId === 'recent' && (
                    <div className="bg-white rounded-b-xl shadow-sm p-6">
                      <div className="space-y-4">
                        {recentCompletions.map((completion) => (
                          <div 
                            key={completion.id}
                            className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                          >
                            <div>
                              <h3 className="font-medium text-purple-900">
                                {completion.themeTitle || 'The Best Shared Moments of 2024'} Summary
                                {completion.createdFor && (
                                  <span className="ml-2 text-sm text-gray-600">
                                    (Created for {completion.createdFor})
                                  </span>
                                )}
                              </h3>
                              <p className="text-sm text-gray-500">
                                Completed {new Date(completion.completedAt).toLocaleDateString()}
                              </p>
                            </div>
                            <div className="flex items-center space-x-2">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedThemeId(completion.themeId);
                                  setShowNewSummaryViewer(true);
                                }}
                                className="p-2 text-purple-600 hover:text-purple-700 hover:bg-purple-50 rounded-lg transition-colors"
                              >
                                <FileText className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {themes.map((theme) => {
                const completedDays = Object.keys(themeContent[theme.id]?.responses || {}).length;
                const totalDays = theme.questions.length;
                const progress = (completedDays / totalDays) * 100;
                const isCompleted = !!themeContent[theme.id]?.completedAt;
                const previousThemes = themes.slice(0, themes.indexOf(theme));
                const allPreviousCompleted = previousThemes.every(t => 
                  !!themeContent[t.id]?.completedAt
                );
                const isEligible = themes.indexOf(theme) === 0 || allPreviousCompleted;
                
                return (
                  <div
                    key={theme.id}
                    className={`bg-white p-6 rounded-xl shadow-sm transition-all ${
                      isCompleted ? 'border border-green-200' : 
                      isEligible && !isCompleted ? 'ring-2 ring-purple-400 shadow-lg shadow-purple-100' : 
                      !isEligible ? 'opacity-50 cursor-pointer hover:opacity-60' : ''
                    }`}
                    title={!isEligible ? 'Complete earlier themes to access this theme' : ''}
                  >
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="text-xl font-semibold text-purple-900">{theme.title}</h3>
                        <p className="text-gray-900 mt-1">{theme.description}</p>
                        <button
                          onClick={() => setExpandedThemeId(expandedThemeId === theme.id ? null : theme.id)}
                          className="mt-2 text-sm text-purple-600 hover:text-purple-700"
                        >
                          {expandedThemeId === theme.id ? 'Hide Blueberries' : 'Preview Blueberries'}
                        </button>
                      </div>
                    </div>

                    {expandedThemeId === theme.id && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        className="overflow-hidden mb-4"
                      >
                        <div className="space-y-2">
                          {theme.questions.map((question, index) => (
                            <div
                              key={question.id}
                              className="flex items-center space-x-2 text-sm text-gray-600 p-2 rounded-lg hover:bg-purple-50"
                            >
                              <span className="w-5 h-5 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-medium">
                                {index + 1}
                              </span>
                              <span className="flex-1">{question.text}</span>
                            </div>
                          ))}
                        </div>
                      </motion.div>
                    )}

                    <div className="space-y-2">
                      <div className="flex justify-between items-center text-sm">
                        <span className="text-gray-500">
                          {isCompleted ? 'Completed' : `${completedDays}/${totalDays} completed`}
                        </span>
                        <button
                          onClick={() => handleThemeSelect(theme)}
                          className={`text-purple-600 font-medium hover:text-purple-700 ${
                            !isEligible ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                          disabled={!isEligible}
                        >
                          {!isEligible ? 'Complete previous themes first' :
                           isCompleted ? 'View Summary' : 
                           completedDays > 0 ? 'Continue' : 'Start'}
                        </button>
                      </div>
                      <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                        <div
                          className={`h-full transition-all duration-300 ${
                            isCompleted ? 'bg-green-500' : isEligible ? 'bg-purple-600' : 'bg-gray-300'
                          }`}
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <QuestionModal
            theme={selectedTheme}
            currentQuestion={currentDay}
            answers={themeContent[selectedTheme?.id]?.responses || {}}
            onClose={() => setShowThemeGrid(true)}
            onAnswer={(questionId, answer) => {
              if (!selectedTheme) return;
              setThemeContent(prev => ({
                ...prev,
                [selectedTheme.id]: {
                  ...prev[selectedTheme.id],
                  responses: {
                    ...(prev[selectedTheme.id]?.responses || {}),
                    [questionId]: answer
                  }
                }
              }));
              setCurrentContent(answer);
            }}
            onNext={() => setCurrentDay(prev => prev + 1)}
            onPrevious={() => setCurrentDay(prev => prev - 1)}
            onComplete={handleSaveResponse}
            isGeneratingSummary={isGeneratingSummary}
          />
        )}
      </div>
      
      {selectedTheme && (
        <ThemeOverview
          isOpen={showThemeOverview}
          onClose={() => setShowThemeOverview(false)}
          title={selectedTheme.title}
          description={selectedTheme.description}
          introduction={experience.customPrompt || ''}
          showCreatedFor={selectedTheme.showCreatedFor}
          onCreatedForChange={setCreatedFor}
          createdFor={createdFor}
          onStart={handleStartTheme}
        />
      )}
      
      <LockedThemeModal
        isOpen={showLockedModal}
        onClose={() => setShowLockedModal(false)}
      />
      {(isGeneratingSummary) && <ZenLoader />}
      {selectedThemeId && user && (
        <ThemeSummaryViewer
          isOpen={showNewSummaryViewer}
          onClose={() => {
            setShowNewSummaryViewer(false);
            setSelectedThemeId(null);
          }}
          themeId={selectedThemeId}
          userId={user.uid}
        />
      )}
    </div>
  );
}