import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Check, ArrowRight, ChevronDown, ChevronUp } from 'lucide-react';
import PurchaseAuth from './auth/PurchaseAuth';

export default function Purchase() {
  const [showFeatures, setShowFeatures] = useState(false);

  const features = [
    'Unlimited access to Year in Review reflections',
    'Full access to Shared Reflections platform',
    'Daily reflection prompts and insights (coming January)',
    'AI-powered summary generation',
    'Export and share your journey'
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          {/* Left Column - Value Props */}
          <div className="mb-8 lg:mb-0">
            <div className="text-center lg:text-left mb-8">
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className="relative mb-6"
              >
                <div className="text-6xl">🫐</div>
              </motion.div>
              
              <h1 className="text-4xl font-bold text-indigo-900 mb-4">
                Start Your Journey with Finding Blueberries
              </h1>
              <p className="text-xl text-indigo-700 max-w-2xl mx-auto lg:mx-0">
                Join our community of reflective thinkers and unlock your path to personal growth
              </p>
            </div>

            {/* Mobile Features Toggle */}
            <div className="lg:hidden mb-6">
              <button
                onClick={() => setShowFeatures(!showFeatures)}
                className="w-full flex items-center justify-between p-4 bg-white rounded-lg shadow-sm"
              >
                <span className="font-medium text-gray-900">What's Included</span>
                {showFeatures ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
            </div>

            {/* Features List */}
            <div className={`space-y-4 ${showFeatures ? 'block' : 'hidden lg:block'}`}>
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-start space-x-3 bg-white p-4 rounded-lg shadow-sm"
                >
                  <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                  <span className="text-gray-700">{feature}</span>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Right Column - Sign Up Form */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden lg:ml-8">
            <div className="p-8">
              <div className="flex justify-between items-center flex-wrap gap-4 mb-8">
                <div>
                  <h2 className="text-2xl font-semibold text-gray-900">Founding Membership</h2>
                  <p className="mt-2 text-gray-500">Full access to all features</p>
                </div>
                <div className="text-right">
                  <p className="text-4xl font-bold text-indigo-600">$50</p>
                  <p className="text-gray-500">annual membership</p>
                </div>
              </div>

              <PurchaseAuth />

              <p className="text-center text-sm text-gray-500 mt-6">
                By signing up, you agree to our Terms of Service and Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}