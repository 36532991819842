import React, { forwardRef } from 'react';

interface TextInputProps {
  inputText: string;
  onInputChange: (text: string) => void;
  loading?: boolean;
  rows?: number;
}

export const TextInput = forwardRef<HTMLTextAreaElement, TextInputProps>(({ 
  inputText, 
  onInputChange,
  loading = false,
  rows = 12 
}, ref) => {
  return (
    <div className="space-y-4">
      <textarea
        ref={ref}
        placeholder="Enter any text you'd like me to analyze..."
        value={inputText}
        onChange={(e) => onInputChange(e.target.value)}
        rows={rows}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 font-mono text-sm"
      />
    </div>
  );
});

TextInput.displayName = 'TextInput';