import OpenAI from 'openai';
import { toast } from 'react-hot-toast';
import type { AIConfig } from '../components/TextAnalyzer';

// Lazy initialize OpenAI client
let openai: OpenAI | null = null;

function getOpenAIClient() {
  if (!openai) {
    const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
    if (!apiKey || apiKey === 'undefined' || apiKey.startsWith('sk-proj-')) {
      console.error('OpenAI API key not found or invalid');
      throw new Error('Please provide a valid OpenAI API key. The current key appears to be invalid or is a placeholder.');
    }
    openai = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true,
      maxRetries: 3
    });
  }
  return openai;
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function generateStructuredInfo(
  text: string,
  config: AIConfig,
  systemPrompt: string,
  model?: string,
  maxRetries = 3
) {
  console.log('Generating structured info:', { 
    textLength: text.length,
    model: model || config.model,
    temperature: config.temperature 
  });

  let lastError;
  
  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      const client = getOpenAIClient();
      
      // Validate input
      if (!text.trim()) {
        throw new Error('No content provided for summary generation');
      }

      // Extract createdFor from system prompt if present
      const createdForMatch = systemPrompt.match(/Created for: (.*?)(?:\n|$)/);
      const createdFor = createdForMatch ? createdForMatch[1].trim() : null;
      
      console.log('OpenAI Request:', {
        modelUsed: model || config.model,
        inputLength: text.length,
        systemPromptLength: systemPrompt.length,
        createdFor
      });

      const completion = await client.chat.completions.create({
        messages: [
          { role: 'system', content: systemPrompt },
          { role: 'user', content: text }
        ],
        model: model || config.model,
        temperature: config.temperature,
        max_tokens: 2000
      });

      if (!completion.choices[0]?.message?.content) {
        throw new Error('No response received from OpenAI');
      }
      return {
        data: completion.choices[0].message.content,
        usage: completion.usage,
        model: completion.model,
        created: completion.created,
        choices: completion.choices
      };
    } catch (error: any) {
      lastError = error;
      
      console.error('Error in generateStructuredInfo:', error);

      // If it's not a 503 error or we're out of retries, throw immediately
      if (error?.status !== 503 || attempt === maxRetries - 1) {
        const errorMessage = error?.error?.message || 'Failed to generate response';
        toast.error('Unable to generate response. Please try again or contact support.', {
          duration: 5000,
          position: 'top-center'
        });
        throw new Error(
          errorMessage
        );
      }

      // Exponential backoff with jitter
      const backoffMs = Math.min(1000 * Math.pow(2, attempt), 10000) + 
                       Math.random() * 1000;
      await sleep(backoffMs);
    }
  }

  // If we get here, we've exhausted all retries
  throw lastError || new Error('Failed to generate response after multiple attempts');
}