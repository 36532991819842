import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Edit2, Trash2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import type { Theme } from '../../lib/firebase/types';
import EditThemeModal from './EditThemeModal';

interface ThemesListProps {
  experienceId: string;
}

export default function ThemesList({ experienceId }: ThemesListProps) {
  const [themes, setThemes] = useState<Theme[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    fetchThemes();
  }, [experienceId]);

  const fetchThemes = async () => {
    try {
      const q = query(
        collection(db, 'themes'),
        where('experienceId', '==', experienceId),
        orderBy('order', 'asc')
      );
      
      const snapshot = await getDocs(q);
      const themesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Theme[];
      
      setThemes(themesData);
    } catch (error) {
      console.error('Error fetching themes:', error);
      toast.error('Failed to load themes');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (theme: Theme) => {
    if (!window.confirm(`Are you sure you want to delete ${theme.title}?`)) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'themes', theme.id));
      setThemes(prev => prev.filter(t => t.id !== theme.id));
      toast.success('Theme deleted successfully');
    } catch (error) {
      console.error('Error deleting theme:', error);
      toast.error('Failed to delete theme');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-24">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div>
      {themes.length === 0 ? (
        <p className="text-gray-500 text-center py-4">No themes added yet</p>
      ) : (
        <div className="space-y-4 max-h-[500px] overflow-y-auto pr-2">
          {themes.map((theme) => (
            <div
              key={theme.id}
              className="flex items-start justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div>
                <h4 className="font-medium text-gray-900">{theme.title}</h4>
                <p className="text-sm text-gray-500">{theme.description}</p>
                <p className="text-xs text-gray-400 mt-1">
                  {theme.questions.length} questions
                </p>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    setSelectedTheme(theme);
                    setShowEditModal(true);
                  }}
                  className="p-1 text-indigo-600 hover:text-indigo-700"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
                <button
                  onClick={() => handleDelete(theme)}
                  className="p-1 text-red-600 hover:text-red-700"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <EditThemeModal
        isOpen={showEditModal}
        onClose={() => {
          setShowEditModal(false);
          setSelectedTheme(null);
        }}
        theme={selectedTheme}
        experienceId={experienceId}
        onSaved={fetchThemes}
      />
    </div>
  );
}