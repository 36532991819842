import React from 'react';
import { motion } from 'framer-motion';

export function PainSection() {
  return (
    <div className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto text-center"
        >
          <div className="space-y-8">
            <div className="bg-indigo-50 p-8 rounded-2xl">
              <h2 className="text-xl md:text-2xl text-indigo-900 mb-6">
                Feeling Unclear in Your Direction? Struggling to Communicate Your Strengths? Unsure of Your Impact?
              </h2>
              <h3 className="text-1xl md:text-3xl font-bold text-indigo-700">
                You're Not Alone
              </h3>
            </div>

            <p className="text-lg text-gray-600">
              Many people report feeling disconnected—from others and themselves.
              What most people don't realize, however, is how much harder it becomes to see our unique strengths, 
              collaborate with others, and fully realize our potential when disconnection is present.
            </p>

              <h3 className="text-1xl md:text-3xl font-bold text-indigo-700">
              FindingBlueberries is here to change that.
            </h3>
          </div>
        </motion.div>
      </div>
    </div>
  );
}