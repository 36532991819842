export const exampleAnswers = {
  "1.1": "I want to improve my public speaking skills for professional presentations.",
  "1.2": "This goal is important because effective communication will help advance my career and increase my confidence in leadership roles.",
  "1.3": "Success would mean confidently delivering presentations to large groups, receiving positive feedback, and being sought after for speaking opportunities.",
  "2.1": "Current obstacles include fear of public judgment, lack of structured practice time, and limited feedback opportunities.",
  "2.2": "I have access to Toastmasters groups, presentation software, and supportive colleagues willing to provide feedback.",
  "2.3": "I need a speaking coach or mentor, regular speaking opportunities, and a system for tracking improvement.",
  "3.1": "I'll measure progress by tracking presentation frequency, audience size, and feedback scores from listeners.",
  "3.2": "Positive audience engagement, decreased anxiety levels, and requests for future presentations will indicate success.",
  "3.3": "My manager, colleagues, and Toastmasters group members can provide valuable feedback on my progress.",
  "4.1": "I notice I'm most confident when thoroughly prepared and when speaking about topics I'm passionate about.",
  "4.2": "I've learned that my fear decreases significantly after the first few minutes of speaking.",
  "4.3": "These insights will help me focus on preparation and opening strong in future presentations.",
  "5.1": "I will join Toastmasters next week and schedule at least one practice presentation per month.",
  "5.2": "I'll maintain momentum by recording all presentations for self-review and scheduling regular feedback sessions.",
  "5.3": "I will review my progress monthly and adjust my approach based on feedback and results."
};