import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast'; 
import { FileText } from 'lucide-react';
import { collection, query, where, orderBy, getDocs, addDoc, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../lib/firebase';
import ExperienceComplete from './ExperienceComplete';
import ZenLoader from './ZenLoader';
import type { Experience, Theme } from '../../lib/firebase/types';
import ThemeSummaryModal from './ThemeSummaryModal';
import ThemeSummaryViewer from '../shared/ThemeSummaryViewer';
import QuestionModal from '../YourStory/QuestionModal';
import ThemeOverview from '../YourStory/ThemeOverview';
import CompletionModal from '../YourStory/CompletionModal';
import ExperienceIntro from '../shared/ExperienceIntro';
import LockedThemeModal from '../shared/LockedThemeModal';
import { generateStructuredInfo } from '../../lib/openai';

interface ThemeContent {
  responses: { [key: number]: string };
  summary?: string;
  completedAt?: string;
}

export default function YearInReview() {
  const navigate = useNavigate();
  const { experienceRoute } = useParams();
  const { user, userData } = useAuth();
  const [experience, setExperience] = useState<Experience | null>(null);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [currentTheme, setCurrentTheme] = useState<Theme | null>(null);
  const [currentDay, setCurrentDay] = useState(1);
  const [themeContent, setThemeContent] = useState<{ [key: string]: ThemeContent }>({});
  const [showThemeOverview, setShowThemeOverview] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [currentContent, setCurrentContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [enterToSave, setEnterToSave] = useState(true);
  const [showProgress, setShowProgress] = useState(true);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [showCompletion, setShowCompletion] = useState(false);
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [showThemeGrid, setShowThemeGrid] = useState(true);
  const [selectedSummaryTheme, setSelectedSummaryTheme] = useState<Theme | null>(null);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showNewSummaryViewer, setShowNewSummaryViewer] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [expandedThemeId, setExpandedThemeId] = useState<string | null>(null);
  const [createdFor, setCreatedFor] = useState<string>('');
  const [isGeneratingExperienceSummary, setIsGeneratingExperienceSummary] = useState(false);
  const [experienceSummary, setExperienceSummary] = useState<CompletedTheme | null>(null);
  const [hasAllThemesCompleted, setHasAllThemesCompleted] = useState(false);

  // Load completed themes on mount
  useEffect(() => {
    const loadCompletedThemes = async () => {
      if (!user) return;
      
      try {
        const q = query(
          collection(db, 'completedThemes'),
          where('userId', '==', user.uid), 
          where('experienceId', '==', experience?.id),
          orderBy('completedAt', 'desc')
        );
        
        const snapshot = await getDocs(q);
        // Group by themeId and take only the most recent completion
        const completedData = snapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          // Only add if we haven't seen this theme yet (first occurrence is most recent)
          if (!acc[data.themeId]) {
            acc[data.themeId] = {
              responses: data.responses.reduce((r, response) => {
                r[response.questionId] = response.content;
                return r;
              }, {}),
              summary: data.summary,
              completedAt: data.completedAt
            };
          }
          return acc;
        }, {} as { [key: string]: ThemeContent });

        // Check if all themes are completed
        if (themes.length > 0) {
          setHasAllThemesCompleted(themes.every(theme => completedData[theme.id]?.completedAt));
        }
        
        // Check for existing experience summary
        const experienceSummaryDoc = snapshot.docs.find(doc => 
          doc.data().themeType === 'experience_summary'
        );
        if (experienceSummaryDoc) {
          setExperienceSummary(experienceSummaryDoc.data() as CompletedTheme);
        }

        setThemeContent(completedData);
      } catch (error) {
        console.error('Error loading completed themes:', error);
      }
    };

    if (experience?.id) {
      loadCompletedThemes();
    }
  }, [user, experience]);

  useEffect(() => {
    const loadExperience = async () => {
      try {
        // Get experience by route
        const route = experienceRoute || 'year-in-review';
        console.log('Loading experience for route:', route);
        
        const experiencesRef = collection(db, 'experiences');
        const experienceQuery = query(
          experiencesRef,
          where('route', '==', route),
          where('isActive', '==', true)
        );
        const experienceSnapshot = await getDocs(experienceQuery);
        const experienceDoc = experienceSnapshot.docs[0];
        if (!experienceDoc) {
          throw new Error(`Experience not found or inactive for route: ${route}`);
        }
        
        const yearInReview = {
          id: experienceDoc.id,
          ...experienceDoc.data()
        } as Experience;
        
        if (!yearInReview) {
          console.error(`Experience "${route}" not found or inactive`);
          toast.error('This experience is not available yet');
          navigate('/');
          return;
        }

        // Get themes
        const themesRef = collection(db, 'themes');
        const themesQuery = query(
          themesRef,
          where('experienceId', '==', yearInReview.id),
          orderBy('order', 'asc')
        );
        const themesSnapshot = await getDocs(themesQuery);
        const themesData = themesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Theme[];
        setThemes(themesData);
        setExperience(yearInReview);
      } catch (error) {
        console.error('Error loading experience:', error);
        toast.error('Failed to load experience');
      } finally {
        setLoading(false);
      }
    };

    loadExperience();
  }, [navigate]);

  const handleGenerateExperienceSummary = async () => {
    if (!user || !userData) return;
    setIsGeneratingExperienceSummary(true);
    
    try {
      // Get all completed themes
      const completedThemes = themes
        .map(theme => themeContent[theme.id])
        .filter(content => content?.completedAt);

      // Format content for OpenAI
      const content = completedThemes
        .map((theme, index) => {
          const themeData = themes[index];
          return `Theme: ${themeData.title}\n\n` +
            Object.entries(theme.responses)
              .map(([questionId, answer]) => {
                const question = themeData.questions.find(q => q.id === parseInt(questionId));
                return `Q: ${question?.text}\nA: ${answer}`;
              })
              .join('\n\n') +
            `\n\nTheme Summary:\n${theme.summary}\n`;
        })
        .join('\n---\n\n');

      const systemPrompt = `Create a comprehensive experience summary that synthesizes insights from all completed themes. Structure the response in markdown format with these sections:

# Experience Journey
A narrative overview connecting insights across all themes
## Key Patterns & Insights
3-5 overarching patterns or insights that emerged
## Core Strengths
3-5 fundamental strengths demonstrated across themes
## Growth & Impact
3-5 areas of significant growth and their impact
## Future Directions
A forward-looking synthesis connecting insights to future opportunities`;

      const response = await generateStructuredInfo(
        content,
        { model: 'gpt-4', temperature: 0.7 },
        systemPrompt
      );

      const timestamp = new Date().toISOString();
      const summaryData = {
        userId: user.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        themeId: 'experience_summary',
        themeType: 'experience_summary',
        experienceId: experience?.id || '',
        experienceName: experience?.title || '',
        responses: [{
          questionId: 1,
          content,
          createdAt: timestamp
        }],
        summary: response.data,
        completedAt: timestamp
      };

      const docRef = await addDoc(collection(db, 'completedThemes'), summaryData);
      setExperienceSummary(summaryData);
      toast.success('Experience summary generated!');
    } catch (error) {
      console.error('Error generating experience summary:', error);
      toast.error('Failed to generate experience summary');
    } finally {
      setIsGeneratingExperienceSummary(false);
    }
  };

  const handleGenerateSummary = async (weekContent: { [key: number]: string }) => {
    if (!currentTheme || !user || !userData) return;
    
    // Validate we have all 5 responses
    if (!weekContent || Object.keys(weekContent).length < 5) {
      toast.error('Please complete all questions before generating summary');
      setShowSummary(false);
      setCurrentDay(5);
      return;
    }
    
    const themeId = currentTheme.id;
    
    try {
      // Format content for OpenAI
      const content = Object.entries(weekContent)
        .sort(([a], [b]) => parseInt(a) - parseInt(b))
        .map(([day, content]) => {
          const question = currentTheme.questions.find(q => q.id === parseInt(day));
          return `Question ${day}: ${question?.text}\nAnswer: ${content}`;
        })
        .join('\n\n');

      const systemPrompt = `Create an inspiring summary that highlights key themes, growth, and insights from the user's reflections. Structure the response in markdown format with these sections:

${createdFor ? `\nCreated for: ${createdFor}\n` : ''}

# Theme Journey
A concise narrative overview of the journey, challenges faced, and growth achieved.

## Highlights and Wins
Key accomplishments and positive moments (2-5 bullets)

## Strengths and Skills Used
Core competencies and abilities demonstrated throughout the journey (2-5 bullets)

## Impact of Actions
The effects of decisions and actions on both personal and professional life (2-5 bullets)

## Potential Future Story
A forward-looking paragraph that builds on the insights and growth shown.`;

      const response = await generateStructuredInfo(
        content,
        { model: 'gpt-4', temperature: 0.7 },
        systemPrompt + (createdFor ? `\n\nIMPORTANT: This summary is specifically created for ${createdFor}. Please personalize the insights and recommendations accordingly.` : ''),
        experience?.experienceSummaryModel || 'gpt-4'
      );

      console.log('Generated summary response:', response);
      console.log('Current theme:', currentTheme);

      if (!response?.data) {
        throw new Error('Failed to generate summary. Please try again.');
      }

      const timestamp = new Date().toISOString();
      const completedTheme = {
        userId: user.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        themeId,
        questions: currentTheme.questions,
        themeType: 'theme',
        experienceId: experience?.id,
        experienceName: experience?.title,
        responses: Object.entries(weekContent).map(([questionId, content]) => ({
          questionId: parseInt(questionId),
          question: currentTheme.questions.find(q => q.id === parseInt(questionId))?.text || '',
          content,
          createdAt: timestamp
        })),
        summary: response.data,
        completedAt: timestamp
      };

      console.log('Saving completed theme:', { completedTheme, currentTheme });
      // Save completed theme
      const docRef = await addDoc(collection(db, 'completedThemes'), completedTheme);
      console.log('Saved completed theme with ID:', docRef);

      // Log the action
      await addDoc(collection(db, 'userActions'), {
        userId: user.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        actionType: 'theme_complete',
        themeId,
        themeName: currentTheme.title,
        experienceId: experience?.id,
        timestamp,
        summary: response.data
      });

      setThemeContent(prev => ({
        ...prev,
        [themeId]: {
          ...prev[themeId],
          responses: weekContent,
          summary: response.data,
          completedAt: timestamp
        }
      }));

      toast.success('Theme completed and saved!');
      setShowSummary(false);
      setCurrentDay(5);
    } catch (error) {
      console.error('Error generating summary:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to generate summary';
      toast.error(errorMessage);
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const handleSaveResponse = async () => {
    if (!currentContent.trim() || !currentTheme) return;
    setIsGeneratingSummary(true);

    try {
      const themeId = currentTheme.id;
      const updatedTheme = {
        ...themeContent,
        [themeId]: {
          ...themeContent[themeId],
          responses: {
            ...(themeContent[themeId]?.responses || {}),
            [currentDay]: currentContent
          }
        }
      };
      setThemeContent(updatedTheme);

      if (currentDay === 5) {
        const responses = updatedTheme[themeId].responses;
        if (Object.keys(responses).length === 5) {
          await handleGenerateSummary(responses);
          setSelectedSummaryTheme(currentTheme);
          setShowSummaryModal(true);
          setShowThemeGrid(true);
          setCurrentTheme(null);
        } else {
          toast.error('Please complete all questions before generating summary');
        }
      } else {
        setCurrentDay(prev => prev + 1);
        setCurrentContent('');
      }
      setIsGeneratingSummary(false);
    } catch (error) {
      console.error('Error saving response:', error);
      toast.error('Failed to save response'); 
      setIsGeneratingSummary(false);
    }
  };

  const handleStartTheme = () => {
    setShowThemeOverview(false);
    if (currentTheme) {
      const existingContent = themeContent[currentTheme.id]?.responses || {};
      const completedTheme = themeContent[currentTheme.id];
      if (completedTheme?.completedAt) {
        setCurrentDay(6);
      } else {
        setCurrentDay(1);
        setCurrentContent(existingContent[1] || '');
      }
    }
    setShowThemeGrid(false);
  };

  const handleThemeSelect = (theme: Theme) => {
    // Check if previous themes are completed
    const previousThemes = themes.slice(0, themes.indexOf(theme));
    const allPreviousCompleted = previousThemes.every(t =>
      !!themeContent[t.id]?.completedAt
    );
    
    if (!allPreviousCompleted && themes.indexOf(theme) > 0) {
      setShowLockedModal(true);
      return;
    }
    
    const completedTheme = themeContent[theme.id];
    setCurrentTheme(theme);
    
    if (completedTheme?.summary) {
      setSelectedSummaryTheme(theme);
      setShowSummaryModal(true);
      return;
    } else {
      // Otherwise show theme overview
      setShowThemeOverview(true);
      setShowThemeGrid(false);
    }
    
    // If theme is completed, start at summary page
    if (completedTheme?.completedAt) {
      setCurrentDay(6);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (!experience) return null;

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {showThemeGrid ? (
          <>
            <div className="mb-8">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">{experience.title}</h1>
              <p className="text-gray-600">{experience.description}</p>
              {hasAllThemesCompleted && (
                <ExperienceComplete
                  onGenerateSummary={handleGenerateExperienceSummary}
                  onViewSummary={() => {
                    setSelectedThemeId('experience_summary');
                    setShowNewSummaryViewer(true);
                  }}
                  isGenerating={isGeneratingExperienceSummary}
                  hasSummary={!!experienceSummary}
                />
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {themes.map((theme) => {
              const completedDays = Object.keys(themeContent[theme.id]?.responses || {}).length;
              const totalDays = 5;
              const progress = (completedDays / totalDays) * 100; 
              const isCompleted = !!themeContent[theme.id]?.completedAt;
              const previousThemes = themes.slice(0, themes.indexOf(theme));
              const allPreviousCompleted = previousThemes.every(t => 
                !!themeContent[t.id]?.completedAt
              );
              const isEligible = themes.indexOf(theme) === 0 || allPreviousCompleted;
              
              return (
              <motion.div
                key={theme.id}
                whileHover={{ scale: 1.02 }}
                className={`bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-all cursor-pointer ${
                  isCompleted ? 'border border-green-200' : 
                  isEligible && !isCompleted ? 'ring-2 ring-purple-400 shadow-lg shadow-purple-100' : 
                  !isEligible ? 'opacity-50 cursor-pointer hover:opacity-60' : ''
                }`}
                title={!isEligible ? 'Complete earlier themes to access this theme' : ''}
                onClick={() => handleThemeSelect(theme)}
              >
                <div className="flex justify-between items-start mb-4">
                  <h3 className="text-xl font-semibold text-[#1a202c]">{theme.title}</h3>
                </div>
                <p className="text-gray-600 mb-4">{theme.description}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandedThemeId(expandedThemeId === theme.id ? null : theme.id);
                  }}
                  className="text-sm text-purple-600 hover:text-purple-700 mb-4"
                >
                  {expandedThemeId === theme.id ? 'Hide Preview' : 'Preview Blueberries'}
                </button>
                <motion.div
                  initial={false}
                  animate={{ height: expandedThemeId === theme.id ? 'auto' : 0 }}
                  className="overflow-hidden"
                >
                  <div className="space-y-2 mb-4">
                  {theme.questions.map((question, index) => (
                    <div
                      key={question.id}
                      className="flex items-center space-x-2 text-sm text-gray-600 p-2 rounded-lg hover:bg-purple-50"
                    >
                      <span className="w-5 h-5 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-medium">
                        {index + 1}
                      </span>
                      <span className="flex-1">{question.text}</span>
                    </div>
                  ))}
                  </div>
                </motion.div>
                <div className="mt-4 space-y-2">
                  <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-500">
                      {isCompleted ? `Completed ${new Date(themeContent[theme.id].completedAt).toLocaleDateString()}` : `${completedDays}/${totalDays} completed`}
                    </span>
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isCompleted) {
                            setSelectedSummaryTheme(theme);
                            setShowSummaryModal(true);
                          } else {
                            handleThemeSelect(theme);
                          }
                        }}
                        className={`text-purple-600 font-medium hover:text-purple-700 ${
                          !isEligible && !isCompleted ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        {!isEligible ? 'Complete previous themes first' :
                          isCompleted ? 'View Summary' : 
                          completedDays > 0 ? 'Continue' : 'Start'
                        }
                      </button>
                      {isCompleted && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedThemeId(theme.id);
                            setShowNewSummaryViewer(true);
                          }}
                          className="text-purple-600 hover:text-purple-700"
                        >
                          <FileText className="w-5 h-5" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className={`h-full transition-all duration-300 ${
                        isCompleted ? 'bg-green-500' : isEligible ? 'bg-purple-600' : 'bg-gray-300'
                      }`}
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
                {isCompleted && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentTheme(theme);
                      setCurrentDay(1);
                      setShowSummary(false);
                      setShowThemeGrid(false);
                    }}
                    className="mt-2 text-sm text-purple-600 hover:text-purple-700"
                  >
                    Revisit Theme
                  </button>
                )}
              </motion.div>
              );
              })}
            </div>
          </>
        ) : (
          <QuestionModal
            theme={currentTheme}
            currentQuestion={currentDay}
            summary={themeContent[currentTheme?.id]?.summary}
            answers={themeContent[currentTheme?.id]?.responses || {}}
            isGeneratingSummary={isGeneratingSummary}
            onClose={() => setShowThemeGrid(true)}
            onAnswer={(questionId, answer) => {
              if (!currentTheme) return;
              setCurrentContent(answer);
              setThemeContent(prev => ({
                ...prev,
                [currentTheme.id]: {
                  ...prev[currentTheme.id],
                  responses: {
                    ...(prev[currentTheme.id]?.responses || {}),
                    [questionId]: answer
                  }
                }
              }));
            }}
            onNext={() => setCurrentDay(prev => prev + 1)}
            onPrevious={() => setCurrentDay(prev => prev - 1)}
            onComplete={handleSaveResponse}
            onDaySelect={(day) => {
              if (day === 6 && themeContent[currentTheme?.id]?.summary) {
                setSelectedSummaryTheme(currentTheme);
                setShowSummaryModal(true);
                setShowThemeGrid(true);
                setCurrentTheme(null);
              } else {
                setCurrentDay(day);
              }
            }}
          />
        )}
      </main>

      {currentTheme && (
        <ThemeOverview
          isOpen={showThemeOverview}
          onClose={() => setShowThemeOverview(false)}
          title={currentTheme.title}
          description={currentTheme.description}
          introduction={experience.customPrompt || ''}
          showCreatedFor={currentTheme.showCreatedFor}
          onCreatedForChange={setCreatedFor}
          createdFor={createdFor}
          onStart={handleStartTheme}
        />
      )}

      {showLockedModal && (
        <LockedThemeModal
          isOpen={showLockedModal}
          onClose={() => setShowLockedModal(false)}
        />
      )}
      
      {(isGeneratingSummary || isGeneratingExperienceSummary) && <ZenLoader />}

      {selectedSummaryTheme && showSummaryModal && (
        <ThemeSummaryModal
          isOpen={showSummaryModal}
          onClose={() => setShowSummaryModal(false)}
          theme={selectedSummaryTheme}
          summary={themeContent[selectedSummaryTheme.id]?.summary || ''}
          answers={themeContent[selectedSummaryTheme.id]?.responses || {}}
        />
      )}
      
      {showCompletion && (
        <CompletionModal
          onClose={() => setShowCompletion(false)}
          weekNumber={currentTheme?.order || 1}
        />
      )}
      
      <LockedThemeModal
        isOpen={showLockedModal}
        onClose={() => setShowLockedModal(false)}
      />
      {selectedThemeId && user && (
        <ThemeSummaryViewer
          isOpen={showNewSummaryViewer}
          onClose={() => {
            setShowNewSummaryViewer(false);
            setSelectedThemeId(null);
          }}
          themeId={selectedThemeId}
          userId={user.uid}
        />
      )}
    </div>
  );
}