import React, { useState, useEffect } from 'react';
import { X, Search } from 'lucide-react';
import { doc, updateDoc, collection, query, getDocs, arrayUnion, arrayRemove, where, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';

interface Group {
  id: string;
  group_name: string;
  group_code: string;
  seats_remaining: number;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  access_level: string;
  group?: string;
  membership_start?: string;
  membership_end?: string;
}

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  onUserUpdated: () => void;
}

export default function EditUserModal({ isOpen, onClose, user, onUserUpdated }: EditUserModalProps) {
  const [editForm, setEditForm] = useState(user);
  const [loading, setLoading] = useState(false);
  const [groupSearch, setGroupSearch] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);
  const [showGroupResults, setShowGroupResults] = useState(false);

  useEffect(() => {
    if (user.group) {
      searchGroups(user.group);
    }
  }, [user.group]);

  const searchGroups = async (term: string) => {
    try {
      const groupsRef = collection(db, 'groups');
      const q = query(groupsRef);
      const snapshot = await getDocs(q);
      
      const results = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Group))
        .filter(group => 
          group.group_name.toLowerCase().includes(term.toLowerCase()) &&
          ((group.total_seats - (group.members?.length || 0)) > 0 || group.group_code === user.group)
        );
      
      setGroups(results);
    } catch (error) {
      console.error('Error searching groups:', error);
      toast.error('Failed to search groups');
    }
  };

  const handleGroupSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setGroupSearch(term);
    setShowGroupResults(true);
    searchGroups(term);
  };

  const selectGroup = (group: Group) => {
    setSelectedGroup(group);
    setGroupSearch(group.group_name);
    setShowGroupResults(false);
    setEditForm(prev => ({
      ...prev,
      group: group.group_code,
      access_level: 'group_membership'
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const timestamp = new Date().toISOString();

    try {
      // If user was in a previous group, remove from that group
      if (user.group && user.group !== editForm.group) {
        const oldGroupQuery = query(collection(db, 'groups'), where('group_code', '==', user.group));
        const oldGroupSnapshot = await getDocs(oldGroupQuery);
        if (!oldGroupSnapshot.empty) {
          const oldGroupDoc = oldGroupSnapshot.docs[0];
          const oldGroupData = oldGroupDoc.data();
          await updateDoc(doc(db, 'groups', oldGroupDoc.id), {
            members: arrayRemove(user.id),
            seats_used: Math.max(0, (oldGroupData.seats_used || 0) - 1),
            seats_remaining: (oldGroupData.seats_remaining || 0) + 1
          });
        }
      }

      // Update membership period if joining a group
      if (editForm.access_level === 'group_membership' && selectedGroup) {
        const groupData = await getDoc(doc(db, 'groups', selectedGroup.id));
        if (groupData.exists()) {
          const data = groupData.data();
          await updateDoc(doc(db, 'groups', selectedGroup.id), {
            members: arrayUnion(user.id),
            seats_used: (data.seats_used || 0) + 1,
            seats_remaining: Math.max(0, (data.seats_remaining || 0) - 1)
          });
          editForm.membership_start = timestamp;
          editForm.membership_end = data.end_date;
        }
      }

      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        firstName: editForm.firstName,
        lastName: editForm.lastName,
        group: editForm.group,
        access_level: editForm.access_level,
        membership_start: editForm.membership_start,
        membership_end: editForm.membership_end
      });

      toast.success('User updated successfully');
      onUserUpdated();
      onClose();
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Failed to update user');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Edit User</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                value={editForm.firstName}
                onChange={(e) => setEditForm({ ...editForm, firstName: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                value={editForm.lastName}
                onChange={(e) => setEditForm({ ...editForm, lastName: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Access Level</label>
            <select
              value={editForm.access_level}
              onChange={(e) => setEditForm({ ...editForm, access_level: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="free">Free</option>
              <option value="limited_trial">Limited Trial</option>
              <option value="group_membership">Group Membership</option>
              <option value="personal_membership">Personal Membership</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          {editForm.access_level === 'group_membership' && (
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700">Select Group</label>
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  value={groupSearch}
                  onChange={handleGroupSearch}
                  onFocus={() => setShowGroupResults(true)}
                  placeholder="Search for a group..."
                  className="mt-1 block w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              
              {showGroupResults && groups.length > 0 && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                  {groups.map(group => (
                    <div
                      key={group.id}
                      onClick={() => selectGroup(group)}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <div className="font-medium">{group.group_name}</div>
                      <div className="text-sm text-gray-500">
                        {group.total_seats - (group.members?.length || 0)} seats available
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Membership Start</label>
              <input
                type="date"
                value={editForm.membership_start?.split('T')[0] || ''}
                onChange={(e) => setEditForm({ ...editForm, membership_start: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Membership End</label>
              <input
                type="date"
                value={editForm.membership_end?.split('T')[0] || ''}
                onChange={(e) => setEditForm({ ...editForm, membership_end: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}