import React from 'react';

export default function AdminDashboard() {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">
        Welcome to the Admin Dashboard
      </h2>
      <p className="text-gray-600">
        Select an option from the menu to get started.
      </p>
    </div>
  );
}