import React from 'react';
import { motion } from 'framer-motion';
import { CalendarDays, BookOpen, Users, Sparkles } from 'lucide-react';

export function HowItWorksSection() {
  const features = [
    {
      icon: CalendarDays,
      title: 'Daily Reflections',
      description: 'Log meaningful moments and track the good things happening in your life.'
    },
    {
      icon: BookOpen,
      title: 'Deep Dive Reflections',
      description: 'Answer guided questions to create year-end summaries that capture your highlights, strengths, and growth.'
    },
    {
      icon: Users,
      title: 'Shared Reflections',
      description: 'Send value summaries to others, highlighting their impact and creating opportunities for connection.'
    },
    {
      icon: Sparkles,
      title: 'AI-Powered Summaries',
      description: 'Get personalized insights that show your strengths, impact, and potential. From weekly updates to year-end reflections, these summaries will help you see yourself—and others—more clearly.'
    }
  ];

  return (
    <div className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            How It Works
          </h2>
          <p className="text-xl text-gray-600">
            Simple Questions. Powerful Insights.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-gradient-to-br from-indigo-50 to-white p-8 rounded-xl shadow-lg"
              >
                <div className="flex items-center space-x-4 mb-4">
                  <div className="p-3 bg-indigo-100 rounded-lg">
                    <Icon className="w-6 h-6 text-indigo-600" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900">
                    {feature.title}
                  </h3>
                </div>
                <p className="text-gray-600 ml-[52px]">
                  {feature.description}
                </p>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}